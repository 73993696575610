import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import Sidebar from "../layouts/Sidebar";
import ListControls from "../components/list/ListControls";
import Topbar from "../layouts/Topbar";

//api
import apiService from "../services/apiService";

const QnrItemEdit = (props) => {
    const [mainWidth, setMainWidth] = useState("250px");

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
    };

    //從前一頁獲取data
    const location = useLocation();
    const category_id = location.state?.target?.id ?? null;
    const category_name = location.state?.target?.name ?? null;

    const [getSIData, setGetSIData] = useState([]);
    // 排序資料
    const sortByCategorySort = (data) => {
        return data.sort((a, b) => a.sort - b.sort);
    };
    // get_many  -----------------------------------------------------------------
    const fetchManyData = async (collection_name, query, setState) => {
        try {
            const result = await apiService.getMany(collection_name, query, null);
            setState(Array.isArray(result.Data) ? sortByCategorySort(result.Data) : []);
            // console.log(`${collection_name} Data:`, result.Data);
        } catch (error) {
            console.error(`${collection_name} API request error:`, error);
        }
    };
    useEffect(() => {

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TN_SurveyItem', {category_id: category_id}, setGetSIData)
            ]);
        };

        fetchAllData();
    },[category_id]);

    const [siidata, setSIData] = useState(
        {
            id: 0,
            category_id: category_id,
            identifier: 0,
            name: "",
            type: 0,
            createTime: new Date(),
            updateTime: new Date(),
            state: 0,
            sort: 0
        }
    ) ;

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">問卷項目管理</h1>
                    <div className="row_small">
                        <h2 className="subtitle">編輯&ensp;<span>{category_name}</span>&ensp;類組</h2>
                        <h3 className="h3subtitle">已有條件化因子的細部項目。</h3>
                        <h3 className="h3subtitle"><span>現有 {getSIData.length} 個項目</span></h3>
                    </div>
                    <ListControls 
                        isEdit={true}
                        data={getSIData} setData={setGetSIData} 
                        tableName={"TN_SurveyItem"} addData={siidata} setAddData={setSIData}
                        
                        category_id={category_id} tableNameMatrix={"TN_HealthCheckItem"} 
                    />
                    <div className="container_between">
                        <div className="common" onClick={()=> changePage("/SurveyEdit")}>Previous</div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default QnrItemEdit;

