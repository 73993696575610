import React, { useState, useRef, useEffect } from 'react';

const Frame = ({ children, onSelectionChange }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionBox, setSelectionBox] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const startPos = useRef({ x: 0, y: 0 });
  const selectionBoxRef = useRef(null);
  const frameRef = useRef(null);

  const onMouseDown = (e) => {
    if (frameRef.current) {
      const frameRect = frameRef.current.getBoundingClientRect();
      setIsSelecting(true);
      startPos.current = { x: e.clientX - frameRect.left, y: e.clientY - frameRect.top };
      setSelectionBox({ left: e.clientX - frameRect.left, top: e.clientY - frameRect.top, width: 0, height: 0 });
    }
  };

  const onMouseMove = (e) => {
    if (!isSelecting || !frameRef.current) return;
    const frameRect = frameRef.current.getBoundingClientRect();
    const currentX = e.clientX - frameRect.left;
    const currentY = e.clientY - frameRect.top;
    const newSelectionBox = {
      left: Math.min(currentX, startPos.current.x),
      top: Math.min(currentY, startPos.current.y),
      width: Math.abs(currentX - startPos.current.x),
      height: Math.abs(currentY - startPos.current.y),
    };
    setSelectionBox(newSelectionBox);
  };

  const onMouseUp = () => {
    setIsSelecting(false);
    if (onSelectionChange && frameRef.current) {
      const frameWidth = frameRef.current.getBoundingClientRect().width;
      onSelectionChange({ ...selectionBox, frameWidth });
    }
  };

  useEffect(() => {
    if (!isSelecting) return;

    const handleMouseMove = (e) => onMouseMove(e);
    const handleMouseUp = () => onMouseUp();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting]);

  return (
    <div className="frame" ref={frameRef} onMouseDown={onMouseDown} style={{ position: 'relative', userSelect: 'none' }}>
      {children}
      {isSelecting && (
        <div
          ref={selectionBoxRef}
          className="selection-box"
          style={{
            position: 'absolute',
            border: '2px dashed dodgerblue',
            backgroundColor: '#5289dd30',
            left: `${selectionBox.left}px`,
            top: `${selectionBox.top}px`,
            width: `${selectionBox.width}px`,
            height: `${selectionBox.height}px`,
          }}
        />
      )}
    </div>
  );
};

export default Frame;



// <Frame setFrameWidth={setFrameWidth}> 
// import React, { useState, useRef, useEffect } from 'react';

// const Frame = ({ children, onSelectionChange, setFrameWidth }) => {
//   const [isSelecting, setIsSelecting] = useState(false);
//   const [selectionBox, setSelectionBox] = useState({ left: 0, top: 0, width: 0, height: 0 });
//   const startPos = useRef({ x: 0, y: 0 });
//   const selectionBoxRef = useRef(null);
//   const frameRef = useRef(null);

//   const onMouseDown = (e) => {
//     if (frameRef.current) {
//       const frameRect = frameRef.current.getBoundingClientRect();
//       setIsSelecting(true);
//       startPos.current = { x: e.clientX - frameRect.left, y: e.clientY - frameRect.top };
//       setSelectionBox({ left: e.clientX - frameRect.left, top: e.clientY - frameRect.top, width: 0, height: 0 });
//     }
//   };

//   const onMouseMove = (e) => {
//     if (!isSelecting || !frameRef.current) return;
//     const frameRect = frameRef.current.getBoundingClientRect();
//     const currentX = e.clientX - frameRect.left;
//     const currentY = e.clientY - frameRect.top;
//     const newSelectionBox = {
//       left: Math.min(currentX, startPos.current.x),
//       top: Math.min(currentY, startPos.current.y),
//       width: Math.abs(currentX - startPos.current.x),
//       height: Math.abs(currentY - startPos.current.y),
//     };
//     setSelectionBox(newSelectionBox);
//   };

//   // const onMouseUp = () => {
//   //   setIsSelecting(false);
//   //   if (onSelectionChange && frameRef.current) {
//   //     const frameWidth = frameRef.current.getBoundingClientRect().width;
//   //     onSelectionChange({ ...selectionBox, frameWidth });
//   //   }
//   // };
//   const onMouseUp = () => {
//     setIsSelecting(false);
//   };

//   useEffect(() => {
//     const updateFrameWidth = () => {
//       if (frameRef.current) {
//         const frameWidth = frameRef.current.getBoundingClientRect().width;
//         setFrameWidth(frameWidth);
//       }
//     };

//     window.addEventListener('resize', updateFrameWidth);

//     // Initial call to set the frame width
//     updateFrameWidth();

//     return () => {
//       window.removeEventListener('resize', updateFrameWidth);
//     };
//   }, [setFrameWidth]);

//   useEffect(() => {
//     if (!isSelecting) return;

//     const handleMouseMove = (e) => onMouseMove(e);
//     const handleMouseUp = () => onMouseUp();

//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [isSelecting]);

//   return (
//     <div className="frame" ref={frameRef} onMouseDown={onMouseDown} style={{ position: 'relative', userSelect: 'none' }}>
//       {children}
//       {isSelecting && (
//         <div
//           ref={selectionBoxRef}
//           className="selection-box"
//           style={{
//             position: 'absolute',
//             border: '2px dashed dodgerblue',
//             backgroundColor: '#5289dd30',
//             left: `${selectionBox.left}px`,
//             top: `${selectionBox.top}px`,
//             width: `${selectionBox.width}px`,
//             height: `${selectionBox.height}px`,
//           }}
//         />
//       )}
//     </div>
//   );
// };

// export default Frame;
