import React, { useRef, useEffect, useState } from "react";
import { useSidebar } from "../context/SidebarContext";
import { useNavigate } from 'react-router-dom';

import PersonIcon from '@mui/icons-material/Person';
import BedtimeIcon from '@mui/icons-material/Bedtime';

import './Topbar.css';

const Topbar = () =>{
    const { protectEyesMode, toggleProtectEyesMode, //護眼模式
        setIsLoggedIn, setUserId, setUserPermissions //登入者資訊
      } = useSidebar();
    
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        if(option === "/"){
            // 登出
            setIsLoggedIn(false);
            setUserId(null);
            setUserPermissions(null);
        }

        navigate(option);
    };

    
    useEffect(() => {
        const htmlElement = document.documentElement;
        if (protectEyesMode) {
            htmlElement.classList.add('protect_eyes_mode');
        } else {
            htmlElement.classList.remove('protect_eyes_mode');
        }
    }, [protectEyesMode]);

    return (
        <div className="topbar">
            <div className="logo">Relation Builder</div>

            <div className="container_flex_big">
                {/* <span className={'sidebar_icon person'}></span> */}
                <PersonIcon onClick={()=> changePage("/User")} fontSize="large" className="unborder_btn"/>
                <BedtimeIcon onClick={()=>toggleProtectEyesMode()} className="unborder_btn moon"/>
                <div className="common" onClick={()=> changePage("/")}>Logout</div>
            </div>

        </div>
    );
    
}

export default Topbar;
