import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//api
import apiService from "../../services/apiService";

import "./login.css";

// **********************忘記密碼**************************** //
const ForgetPwd = () => {
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();

    const [account, setAccount] = useState('');
    const handleInputChange = (event) => {
        setAccount(event.target.value);
    };

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const changePage = async (option) => {
        try {
            const result = await apiService.checkAccount({ userAccount: account });
            if(!result.status){
                setErrorSnackbarOpen(true);
                setSnackbarMessage('請輸入有效帳戶');
                return
            } 

            const result2 = await apiService.sendEmail2( account );
            //判定有無信箱
            if(result2.status){
                setSuccessSnackbarOpen(true);
                setSnackbarMessage('重設密碼通知已寄出');
            }

        } catch (error) {
            console.error("Failed to check account:", error);
        }
    };

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            changePage();
        }
    }

    const handleClose = () => {
        setErrorSnackbarOpen(false);
        setSuccessSnackbarOpen(false)
    };

    useEffect(()=>{
        const handleGlobalKeyPress = (event) => {
            if (event.key === 'Enter'){
                changePage("/Relation");
            }
        };

        document.addEventListener('keydown', handleGlobalKeyPress);
        return()=>{
            document.removeEventListener('keydown', handleGlobalKeyPress);
        };
    },[]);

    return (
        <div className="background-container">
            <main className="login_contaniner" onSubmit={(e) => { e.preventDefault(); }}>
                {/* <form  className="row" onSubmit={() => changePage("/Relation")}> */}
                <form  className="row">
                    <h1 className="maintitle">忘記密碼?</h1>
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Email"
                        value={account}
                        onChange={handleInputChange}
                    ></input>
                    <input className="submit_btn" type="submit" value="Continue" onClick={() => changePage("/Relation")} onKeyDown={handleKeyPress}></input>
                    <input className="submit_btn2" type="submit" value="上一步" onClick={() => navigate("/")}></input>
                </form >

                <Snackbar 
                    open={errorSnackbarOpen} 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={handleClose}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

                <Snackbar 
                    open={successSnackbarOpen} 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={handleClose}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setErrorSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </main>
            </div>
    );
};

export default ForgetPwd;
