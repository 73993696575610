import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';

//api
import apiService from "../../services/apiService";

function createData_one(identifier, name, createTime, updateTime, user) {
  return {
    identifier,
    name,
    createTime,
    updateTime,
    user
  };
}

function Row(props) {
  const { row, data, index, setData, 
    isEdit, isCheck,
    setTarget,
    previousPage,
    toPage,
    //勾選
    selected, onCheckboxChange

  } = props;

  // 使用 useNavigate 鉤子進行路由導航
  const navigate = useNavigate();

  const handleRow = (value, option) => {
    //若為資料夾(單選)
    if(option === "/RelationFolder" || option === "/HeatMapFolder"){
      const newData = { previousPage: previousPage, target: value };
      setTarget(newData);
      navigate(option);
    } 
    //若為多選
    else{
      setTarget(prev => {
        const valueExists = prev.includes(value);
        if (valueExists) {
            return prev.filter(item => item !== value);
        } else {
            return [...prev, value];
        }
      });
      onCheckboxChange(index)
    }
  };

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);


  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  //------------------------------ 更新 ------------------------------//
  const handleEditCheckup = async (editName) => {
    try{
      const id = data.id;
      const originalItem = data;
      const updatedItem = {
        ...originalItem,
        name: editName,
        updateTime: new Date()
      };
      await apiService.updateOne("TR_RelationFolder", { id: id }, updatedItem)
      // 更新完成後，重新從 API 獲取最新的資料
      const result = await apiService.getMany("TR_RelationFolder", { }, null);
      if (!result.Status) {
          console.error('Failed to update document:', result.MSG);
          return;
      } 
      setData(Array.isArray(result.Data) ? result.Data : []);
    } catch (error){
      console.error('API request error:', error);
    }
  };

  //------------------------------ 刪除 ------------------------------//
  const handleDeleteCheckup = async () => {
    const id = data.id;
    try {
      const result = await apiService.deleteOne("TR_RelationFolder", { id: id });
      if (!result.Status) {
        console.error('Failed to update document into + :', result.MSG);
        return;
      }

        const updateResult = await apiService.getMany("TR_Relation", {relationFolder_id: id}, null);
        if(!updateResult.Status){
            console.error(`Failed to update relation with ${id}:`, updateResult.MSG);
            return;
        }
        //更新多個關係表
        for(let item of updateResult.Data){
          const updatedItem = {
            ...item,
            relationUpdateTime: new Date().toISOString(),
            relationFolder_id: 0
          };
          const updateResponse  = await apiService.updateOne("TR_Relation", {id: item.id}, updatedItem);

          if(!updateResponse.Status){
              console.error(`Failed to update relation with ${item.id}:`, updateResponse.MSG);
              if(updateResponse.MSG === "Document not found"){
                window.location.reload(); //若是因為沒有資料=>直接刷新
              }
          }
        }

        handleClose();

        const result2 = await apiService.getMany("TR_RelationFolder", {}, null);
        if (!result2) {
          if(result2.MSG === "Document not found"){
            window.location.reload(); //若是因為沒有資料=>直接刷新
            console.error('Failed to fetch updated data: ', result2.MSG);
          }
          return;
        }
        setData(Array.isArray(result2.Data) ? result2.Data : []);
        
    } catch (error){
      console.error('API request error:', error);
    }

  };
  
  const formatDateTime = (rawDateTime) => {
    const date = new Date(rawDateTime);
    const formattedDate = date.toLocaleDateString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const formattedTime = date.toLocaleTimeString('zh-CN', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };
  return (
    <React.Fragment>
      <TableRow 
        hover
        onClick={()=>handleRow(data, toPage)}
        role="checkbox"
        tabIndex={-1}
        sx={{ cursor: 'pointer' }}
      >
        {isCheck &&
          <TableCell padding="checkbox">
            <Checkbox
              checked={selected}
              onChange={() => onCheckboxChange(index)}
              inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
            />
          </TableCell>
        }
        <TableCell scope="row">
          {index + 1}
        </TableCell>
        <TableCell scope="row">
          {row.identifier}
        </TableCell>
        <TableCell scope="row">
          {row.name}
        </TableCell>
        <TableCell scope="row">
          {formatDateTime(row.createTime)}
        </TableCell>
        <TableCell scope="row">
          {formatDateTime(row.updateTime)}
        </TableCell>
        <TableCell scope="row">
          {row.user}
        </TableCell>

        {isEdit &&
          <TableCell scope="row" align="center">
              {/* event.stopPropagation(); 阻止換頁按鈕 */}
              {/* 更新 */}
              <ModeIcon className='edit_icon' onClick={(event) => {event.stopPropagation(); handleEditDialogOpen()}} />
              {/* 刪除 */}
              &ensp;
              <DeleteIcon className='edit_icon' onClick={(event) => {event.stopPropagation(); handleDeleteDialogOpen()}}/> 
          </TableCell>
        }
      </TableRow>

      {/* 更新 */}
      <Dialog
          open={editDialogOpen}
          onClose={handleClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);;
              const formJson = Object.fromEntries(formData.entries());
              const editName = formJson.editName;

              handleEditCheckup(editName);
              handleClose();
              setSnackbarMessage('成功更新一筆項目');
              setSuccessSnackbarOpen(true);
              },
          }}
          >
              <DialogTitle>更新名稱</DialogTitle>
              <DialogContent>
              <DialogContentText>
                  編輯項目名稱，名稱不超過10字元。
              </DialogContentText>
              <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="editName"
                  label="項目名稱"
                  fullWidth
                  variant="standard"
              />
              </DialogContent>
              <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
              </DialogActions>

      </Dialog>
      {/* 更新成功 */}
      <Snackbar 
            open={successSnackbarOpen}
            autoHideDuration={6000}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
            }}
            onClose={() => setSuccessSnackbarOpen(false)}
            >
            <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
            {snackbarMessage}
            </MuiAlert>
      </Snackbar>


      {/* 刪除 */}
      <Dialog
          open={deleteDialogOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);;
              const formJson = Object.fromEntries(formData.entries());
              const deleteName = formJson.checkupName;
              if(deleteName === row.name){
                handleDeleteCheckup();
                handleClose();
                setSnackbarMessage('成功刪除一筆項目');
                setSuccessSnackbarOpen(true);
              }
              else {
                // 如果條件不成立，設置 Snackbar 的狀態並顯示提示訊息
                setSnackbarMessage('刪除失敗，輸入的名稱與選擇的名稱不符合');
                setErrorSnackbarOpen(true);
              }
            },
          }}
      >
        <DialogTitle>刪除項目</DialogTitle>
        <DialogContent>
          <DialogContentText>
            確定要刪除嗎?項目將永遠清除。
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="checkupName"
            label="項目名稱"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" sx={{color: 'red'}}>Delete</Button>
        </DialogActions>
        {/* 刪除失敗 */}
        <Snackbar 
          open={errorSnackbarOpen} 
          autoHideDuration={6000} 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* 刪除成功 */}
        <Snackbar 
          open={successSnackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
          }}
          onClose={() => setSuccessSnackbarOpen(false)}
          >
          <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
          {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Dialog>

    
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    updateTime: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTable( props ) {
  const { data, setData, userData, 
    isEdit, 
    isCheck, 
    setTarget, 
    previousPage,
    toPage
  } = props;

  const rows = data.map((item) => {
    const userId = parseInt(item.user_id);
    const user = userData.find(u => u.id === userId);
    const userName = user ? user.userName : "無創建者資訊";
    return createData_one(item.identifier ,item.name, item.createTime, item.updateTime, userName)
  })

  const [selected, setSelected] = useState([]);
  const handleCheckboxChange = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="large">
        <TableHead>
          <TableRow>
          {isCheck &&
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={() => {
                    if (selected.length === rows.length) {
                      //全部取消選擇
                      setSelected([]);
                      setTarget([]);
                    } else {
                      //全部選擇
                      setSelected(rows.map((_, index) => index));
                      setTarget(data);
                    }
                  }}
                />
              </TableCell>
            }
            <TableCell align="left">No.</TableCell>
            <TableCell align="left">項目唯一碼</TableCell>
            <TableCell align="left">項目名稱</TableCell>
            <TableCell align="left">創建時間</TableCell>
            <TableCell align="left">更新時間</TableCell>
            <TableCell align="left">創建者</TableCell>
            {isEdit &&
              <TableCell align="center">設定</TableCell>
            }

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return(
              <Row key={index} row={row} index={index}
                data={data[index]} setData={setData}
                isEdit={isEdit} 
                isCheck={isCheck}
                setTarget={setTarget} 
                previousPage={previousPage}
                toPage={toPage} 
                //測試中
                selected={selected.includes(index)}
                onCheckboxChange={handleCheckboxChange}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}