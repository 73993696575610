import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext'

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import HeatMap from "../components/charts/HeatMap";

//api
import apiService from "../services/apiService";

const HeatMapItem = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    const { target, chooseTargets } = useFileContext();

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
    };

    const [getHealthCheckData, setGetHealthCheckData] = useState([]);
    const [getSurveyData, setSurveyData] = useState([]);
    const [doctors, setDoctors] = useState(0);
    const doctorsCount = () => {
        const uniqueUserIds = new Set(chooseTargets.map(item => item.user_id));
        setDoctors(uniqueUserIds.size);
    };

    const chooseList_checked = chooseTargets.flatMap(t => t.chooseList_checked); // 合併所有 target 對象(所選的全部表格)的 chooseList_checked
    const chooseList_indeterminate = chooseTargets.flatMap(t => t.chooseList_indeterminate);// 合併所有 target 對象(所選的全部表格)的 chooseList_indeterminate

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await apiService.getTRRelationMatrixByID({
                    checked_ids: chooseList_checked,
                    indeterminate_ids: chooseList_indeterminate
                });

                if (result.Status) {
                    const { checked_data, indeterminate_data } = result;

                    // 已選狀態 => 權重為1
                    const updatedCheckedData = checked_data.map(item => ({
                        ...item,
                        weight: 1
                    }));

                    // 中間狀態 => 權重為0.5
                    const updatedIndeterminateData = indeterminate_data.map(item => ({
                        ...item,
                        weight: 0.5
                    }));

                    const allFlattenedRelationData = [...updatedCheckedData, ...updatedIndeterminateData]

                    // 初始化
                    const hcItemWeights = {};
                    const surveyItemWeights = {};

                    // 計算權重
                    allFlattenedRelationData.forEach(item => {
                        hcItemWeights[item.hcItem_id] = (hcItemWeights[item.hcItem_id] || 0) + item.weight;
                        surveyItemWeights[item.surveyItem_id] = (surveyItemWeights[item.surveyItem_id] || 0) + item.weight;
                    });

                    // healthCheck、survey資料格式化並加入權重
                    const result2 = await apiService.getTRItemByID({
                        healthCheck: Object.entries(hcItemWeights),
                        survey: Object.entries(surveyItemWeights)
                    });

                    //健檢
                    setGetHealthCheckData(result2.healthCheck_data);
                    //問卷
                    setSurveyData(result2.survey_data)
                }

            } catch (error) {
                console.error("Error fetching relation data:", error);
            }
        };    

        doctorsCount()
        fetchData();
    }, []);

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">熱圖</h1>
                    <h3 className="h3subtitle">
                        <span>總共 {chooseTargets.length} 個表格</span>
                        <span>總共 {doctors} 個醫師</span>
                        {/* <span>總共 {chooseTargets.length} 個表格</span> */}
                    </h3>


                        <HeatMap
                            healthCheckData={getHealthCheckData}
                            surveyData={getSurveyData}
                        />

                    <div className="container_between">
                        <div className="common" onClick={()=> changePage(target.previousPage)}>Previous</div>
                        <div></div>
                    </div>
                </div>
            </main>
        </>
    );
};
export default HeatMapItem;