import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import "./login.css";

//api
import apiService from "../../services/apiService";

// **********************一般登入**************************** //
const ResetPwd = () => {

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
    };

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [openMask, setOpenMask] = React.useState(false);

    const [specialCode, setSpecialCode] = useState("");
    // console.log(specialCode);

    useEffect(() => {
        // 抓 URL 中的参数
        const urlSearchParams = new URLSearchParams(window.location.search);
        const paramValue = urlSearchParams.get("p");
        const timeValue = urlSearchParams.get("t");
        
        // 更新狀態
        setSpecialCode(paramValue || "");

        // handleTimeCheck(timeValue);
    }, []);


    // const handleTimeCheck = async(timeValue) => {
    //     try {
    //         const result = await apiService.verifyTimeStamp(timeValue);
    //         if (!result.status) {
    //             console.log(result.status);
    //             console.log(result.MSG);
    //             alert("認證逾時，將導向登入頁面");
    //             changePage("/");
    //             return;
    //         }
    //         console.log(result.status);
    //         console.log(result.MSG);
            
    //     } catch (error) {
    //         console.error('API request error:', error);
    //     }
    // }

    //------------------------------ 重設密碼 ------------------------------//
    const [passwords, setPasswords] = useState({ password: '', password2: '' });

    const handleInputChange = (event) => {
        setPasswords(prevPasswords => ({
            ...prevPasswords,
            password: event.target.value
        }));
    };

    const handleInputChange2 = (event) => {
        setPasswords(prevPasswords => ({
            ...prevPasswords,
            password2: event.target.value
        }));
    };

    const handleAddCheckup = async () => {
        if(passwords.password === passwords.password2) {
            const tmp = {
                userPassword: passwords.password,
                userState: 1,
                userUpdateTime: new Date()
            }
            try {
                const result = await apiService.resetPwd("TR_User", {userAccount: specialCode}, tmp);
                if (!result.status) {
                    // console.error('Failed to insert document into Merged_Account:', result.MSG);
                    setOpenMask(true);
                    setSuccessSnackbarOpen(true);
                    setSnackbarMessage('修改成功，請重新登入');
                    setTimeout(() => {
                        changePage("/Relation");
                    }, 3000); // 延遲3秒
                    return;
                }
                console.log('Document inserted into Merged_Account successfully:', result.MSG);

                

            } catch (error) {
                setErrorSnackbarOpen(true);
                setSnackbarMessage('Invalid timestamp');
                console.error('API request error:', error);
            }
        } else { 
            setErrorSnackbarOpen(true);
            setSnackbarMessage('輸入密碼不一致');
            return
        }
    };

    // 密碼顯示
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = (event) => {
        setShowPassword(!showPassword);
    };
    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = (event) => {
        setShowPassword2(!showPassword2);
    };

    return (
        <div className="background-container">
            <main className="login_contaniner">
                <div className="row">
                    <h1 className="maintitle">重設密碼</h1>

                    <div className='input_login_box'>
                        <input 
                            className="input" 
                            type= {showPassword ? "text" : "password"}
                            placeholder="New Password"
                            value={passwords.password}
                            onChange={handleInputChange}
                        >
                        </input>
                        <button onClick={togglePasswordVisibility} className={showPassword ? "login_closeEye" : "login_eye"} type="button"></button>
                    </div>

                    <div className='input_login_box'>
                        <input 
                            className="input" 
                            type= {showPassword2 ? "text" : "password"}
                            placeholder="Confirm New Password"
                            value={passwords.password2}
                            onChange={handleInputChange2}
                        >
                        </input>
                        <button onClick={togglePasswordVisibility2} className={showPassword2 ? "login_closeEye" : "login_eye"} type="button"></button>
                    </div>
                    <input className="submit_btn" type="button" value="Set Password" onClick={()=> handleAddCheckup()}></input>
                </div>


                <Snackbar 
                    open={errorSnackbarOpen} 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={() => setErrorSnackbarOpen(false)}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

                <Snackbar 
                    open={successSnackbarOpen} 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={() => setSuccessSnackbarOpen(false)}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setErrorSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openMask}
                >
                    <Stack gap={1} justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                    <Typography>Loading...</Typography>
                    </Stack>
                </Backdrop>
            </main>
        </div>
        
    );
};

export default ResetPwd;
