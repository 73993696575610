// SidebarContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    //側邊欄收合，從localStorage獲取狀態，默認為開啟狀態
    const [isOpen, setIsOpen] = useState(()=>{
        const storedState = localStorage.getItem("sidebarOpen");
        return storedState !== null ? JSON.parse(storedState) : true;
    });

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    // 護眼模式狀態，從localStorage獲取狀態，默認為普通狀態
    const [protectEyesMode, setProtectEyesMode] = useState(()=>{
        const storedState = localStorage.getItem("protectEyesMode");
        return storedState !== null ? JSON.parse(storedState) : false;
    });

    const toggleProtectEyesMode = () => {
        setProtectEyesMode(!protectEyesMode);
    };

    //將狀態儲存到 localStorage
    useEffect(() => {
        localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
        localStorage.setItem("protectEyesMode", JSON.stringify(protectEyesMode));
        // console.log("localStorage status:", localStorage);
    }, [isOpen, protectEyesMode]);

    //登入狀態
    const [isLoggedIn, setIsLoggedIn] = useState(()=>{
        const storedState = localStorage.getItem("isLoggedIn");
        return storedState !== null ? JSON.parse(storedState) : false;
    });

    //登入者id
    const [userId, setUserId] = useState(()=>{
        const storedId = localStorage.getItem("userId");
        return storedId !== null ? Number(storedId) : null;  //避免資料儲存在 localStorage 以字串的模式儲存
    });

    //登入者權限
    const [userPermissions, setUserPermissions] = useState(()=>{
        const storedRole = localStorage.getItem("userRole");
        return storedRole !== null ? Number(storedRole) : null; //避免資料儲存在 localStorage 以字串的模式儲存
    });

    // 當狀態變化時，保存到 localStorage
    useEffect(() => {
        localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
        localStorage.setItem("userId", userId);
        localStorage.setItem("userRole", userPermissions);
    }, [isLoggedIn, userId, userPermissions]);

    return (
        <SidebarContext.Provider value={{ 
            //側邊欄收合
            isOpen, toggleSidebar,
            // 護眼模式狀態
            protectEyesMode, toggleProtectEyesMode,
            //登入者資訊
            isLoggedIn, setIsLoggedIn,
            userId, setUserId,
            userPermissions, setUserPermissions
        }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => useContext(SidebarContext);
