import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const options = ['❒　翻轉筆刷(預設)', '⊟　Indeterminate筆刷', '☑　Checked筆刷', '☐　橡皮擦', '⚠　全部清除', '↶　回復初始狀態'];

export default function SplitButton(props) {
  const { selectedBrushIndex, setSelectedBrushIndex, simple } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedBrushIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedBrushIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        {/* <Button onClick={handleClick}>{options[selectedBrushIndex]}</Button> */}
        <Button>{options[selectedBrushIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {options.map((option, index) => {
                  if (simple && (index === 4 || index === 5)) {
                    return null; // 不顯示索引為 4 和 5 的項目
                  }

                  return (
                    <MenuItem
                      key={option}
                      // disabled={index === 5}
                      selected={index === selectedBrushIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{
                        ...(index === 4 && {
                          backgroundColor: 'rgb(214 114 114);',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: 'rgb(246, 237, 237);',
                            color: 'rgb(240, 79, 79)',
                          },
                        }),
                      }}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </MenuList>

              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}