import React, { createContext, useContext, useState, useEffect } from 'react';

const FileContext = createContext();

export const FileProvider = ({ children }) => {
    // 初始化狀態，從 localStorage 讀取
    const [chooseList, setChooseList] = useState(() => {
        const savedChooseList = localStorage.getItem('chooseList');
        return savedChooseList ? JSON.parse(savedChooseList) : [];
    });

    //checkedBox狀態
    const [checked, setChecked] = useState(() => {
        const savedChecked = localStorage.getItem('checked');
        return savedChecked ? JSON.parse(savedChecked) : [];
    });

    const [target, setTarget] = useState(() => {
        const savedTarget = localStorage.getItem('target');
        return savedTarget ? JSON.parse(savedTarget) : [];
    });

    const [chooseTargets, setChooseTargets] = useState(() => {
        const savedChooseTargets = localStorage.getItem('chooseTargets');
        return savedChooseTargets ? JSON.parse(savedChooseTargets) : [];
    });

    // 當狀態變化時，保存到 localStorage
    useEffect(() => {
        localStorage.setItem('chooseList', JSON.stringify(chooseList));
    }, [chooseList]);

    useEffect(() => {
        localStorage.setItem('checked', JSON.stringify(checked));
    }, [checked]);

    useEffect(() => {
        localStorage.setItem('target', JSON.stringify(target));
    }, [target]);

    useEffect(() => {
        localStorage.setItem('chooseTargets', JSON.stringify(chooseTargets));
    }, [chooseTargets]);

    // console.log("chooseList: ", chooseList);
    // console.log("checked: ", checked);
    // console.log("target: ", target);
    // console.log("chooseTargets: ", chooseTargets);

    // 重設參數
    const resetParameters = () => {
        setChooseList([]);
        setChecked([]);
        setTarget([]);
        setChooseTargets([]);
        localStorage.removeItem('chooseList');
        localStorage.removeItem('checked');
        localStorage.removeItem('target');
        localStorage.removeItem('chooseTargets');
    };

    return (
        <FileContext.Provider value={{ 
            chooseList, setChooseList, 
            checked, setChecked, 
            target, setTarget,
            chooseTargets, setChooseTargets,
            resetParameters }}>
            {children}
        </FileContext.Provider>
    );
};

export const useFileContext = () => useContext(FileContext);
