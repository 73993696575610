import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSidebar } from '../../context/SidebarContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//api
import apiService from "../../services/apiService";

import "./login.css";
// **********************一般登入**************************** //
const Signup = () => {
    const { protectEyesMode, toggleProtectEyesMode, setIsLoggedIn, setUserId, setUserPermissions} = useSidebar();

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();

    const [account, setAccount] = useState('');
    const handleInputChange = (event) => {
        setAccount(event.target.value);
    };
    const [password, setPassword] = useState('');
    const handleInputChange2 = (event) => {
        setPassword(event.target.value);
    };

    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const changePage = async (option) => {
        try {
            const result = await apiService.checkAccount({ userAccount: account });
            if(!result.status){
                setErrorSnackbarOpen(true);
                setSnackbarMessage('請輸入有效帳戶');
                return
            } 
            const result2 = await apiService.getOne("TR_User",{userAccount: account, userPassword: password});
            if(result2.Status){
                // 登入成功
                const mode = protectEyesMode ? 0: 1;
                if(mode === result2.Data.userPreference){
                    toggleProtectEyesMode();
                }
                setIsLoggedIn(true);
                setUserId(result2.Data.id);
                setUserPermissions(result2.Data.userPermissions);

                navigate(option);
            } else{
                setErrorSnackbarOpen(true);
                setSnackbarMessage('密碼有誤');
            }

        } catch (error) {
            console.error("Failed to check account:", error);
        }
    };

    // 'Enter'
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            changePage("/Relation");
        }
    };

    useEffect(()=>{
        const handleGlobalKeyPress = (event) => {
            if (event.key === 'Enter'){
                changePage("/Relation");
            }
        };

        document.addEventListener('keydown', handleGlobalKeyPress);
        return()=>{
            document.removeEventListener('keydown', handleGlobalKeyPress);
        };
    },[]);

    // 密碼顯示
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = (event) => {
        event.preventDefault(); // 防止默認行為
        setShowPassword(!showPassword);
    };

    return (
        <div className="background-container">
            <main className="login_contaniner">
                <form className="row" onSubmit={(e) => { e.preventDefault(); changePage("/Relation"); }}>
                    <h1 className="maintitle">登入</h1>
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Account/Email"
                        value={account}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    ></input>

                    <div className='input_login_box'>
                        <input 
                            className="input" 
                            type= {showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={handleInputChange2}
                            onKeyDown={handleKeyPress}
                        >
                        </input>
                        <button onClick={togglePasswordVisibility} className={showPassword ? "login_closeEye" : "login_eye"} type="button"></button>
                    </div>
                    <input className="submit_btn" type="submit" value="Login"></input>
                    <input className="submit_btn2" type="button" value="忘記密碼?" onClick={() => navigate("/ForgetPwd")}></input>
                </form >

                <Snackbar 
                    open={errorSnackbarOpen} 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={() => setErrorSnackbarOpen(false)}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </main>
        </div>
    );
};

export default Signup;
