import React, { useEffect, useState, useRef } from "react";
import Sortable from 'sortablejs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Checkbox from '@mui/material/Checkbox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useNavigate } from 'react-router-dom';

//api
import apiService from "../../services/apiService";

export default function CheckboxList(props) {
  const { 
    //------------    共同    ------------//
    data, 

    //------------有換頁功能的------------//
    toPage,   //前往目的地
    location, //useLocation

    //----------1.3 HealthCheck----------//
    checked, setChecked, //是否勾選

    //---------- 3、4 大項編輯區 ----------//
    isCategory, 

    //----------3.1、4.1 小項編輯區----------//
    category_id, //附屬關係的大項
    tableNameMatrix, //新增的組合關係表名稱

    //----------編輯功能共同擁有的----------//
    isEdit, 
    setData, //同步Data
    tableName, addData, setAddData,
  } = props;

  // console.log("得到並且排序的資料:", data);
  const [rows, setRows] = React.useState([]);
  
  useEffect(()=> {
    if(data.length > 0) {
      const extractedItems = data.map(item => item.name);
      setRows(extractedItems);
    }
  }, [data]);

  const [rowsid, setRowsid] = React.useState([]);
  
  useEffect(()=> {
    if(data.length > 0) {
      const extractedItems = data.map(item => item.identifier);
      setRowsid(extractedItems);
    }
  }, [data]);
  
  // 使用 useNavigate 鉤子進行路由導航
  const navigate = useNavigate();

  const handleToggle = (value, option) => () => {
    if(checked){
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
          newChecked.push(value); 
      } else {
          newChecked.splice(currentIndex, 1);
      }
  
      // 檢查 toDoList 中是否已經存在該值，若不存在則添加
      if (!checked.includes(value.sort)) {
        setChecked(prevToDoList => {
            const updatedToDoList = [...prevToDoList, value.sort];
            return updatedToDoList;
        });
      }
    }

    if (toPage) {
        const newData = { target: value };
        // 導航時合併新資料到現有 location.state 中
        navigate(option, { state: { ...location.state, ...newData } });
    } else {
        navigate(option);
    }
  };

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [index, setIndex] = React.useState(false);

  const handleEditDialogOpen = (index) => {
    setEditDialogOpen(true);
    setIndex(index);
  };

  const handleDeleteDialogOpen = (index) => {
    setDeleteDialogOpen(true);
    setIndex(index);
  };

  const handleAddDialogOpen = (index) => {
    setAddDialogOpen(true);
    setIndex(index);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
    setAddDialogOpen(false);
  };

  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

    // 排序資料
    const sortByCategorySort = (data) => {
      return data.sort((a, b) => a.sort - b.sort);
  };
  //------------------------------ 更新 ------------------------------//
  const handleEditCheckup = async (editName, indexToEdit) => {
    try{
      const newRows = [...rows];
      newRows.splice(indexToEdit, 1, editName);
      setRows(newRows);
      
      // 資料庫更動
      const originalItem = data.find(dataItem => dataItem.sort === indexToEdit);
      const updatedItem = {
        ...originalItem,
        name: editName,
        updateTime: new Date()
      };
      
      apiService.updateOne(tableName, { category_id: category_id, sort: indexToEdit }, updatedItem)

      // 更新完成後，重新從 API 獲取最新的資料
      const result2 = await apiService.getMany(tableName, { category_id: category_id }, null);
      if (!result2.Status) {
          console.error('Failed to update document:', result2.MSG);
          return;
      } 
      setData(Array.isArray(result2.Data) ? sortByCategorySort(result2.Data) : []);

    } catch (error){
      console.error('API request error:', error);
    }
  };

  //------------------------------ 刪除 ------------------------------//
  const handleDeleteCheckup = async (indexToRemove) => {
    try {
      const newRows = [...rows];
      newRows.splice(indexToRemove, 1);
      setRows(newRows);

      // 資料庫更動=>刪除一筆
      const originalItem = data.find(dataItem => dataItem.sort === indexToRemove);
      // setCategoryUid(originalItem[attributesUID])

      // 類組底下項目刪除
      if(isCategory){
        apiService.deleteMany(isCategory, {category_id: originalItem.id});
      }
    
      await apiService.deleteOne(tableName, { id: originalItem.id })


      //更新其他項目的排序值，檢查其餘順序sortIndex做加入
      newRows.forEach((item, idx) => {
        if(idx >= indexToRemove){
          // console.log("符合條件的item", item);
          const originalItem = data.find(dataItem => dataItem.name === item);
            const updatedItem = {
              ...originalItem,
              sort: originalItem.sort - 1 //後者全部減1
            };
            // console.log("迴圈 ",idx," 更新的資料 ", updatedItem)
            // 呼叫更新 API
            apiService.updateOne(tableName, { id: updatedItem.id }, updatedItem);
        }
      });

      // 在刪除完成後，重新從 API 獲取最新的資料
      const result2 = await apiService.getMany(tableName, { category_id: category_id }, null);
      if (!result2.Status) {
          console.error('Failed to update document:', result2.MSG);
          return;
      }
      setData(Array.isArray(result2.Data) ? sortByCategorySort(result2.Data) : []);



    } catch (error) {
        console.error('API request error:', error);
    }
  }


  //------------------------------ 新增 ------------------------------//
  const handleAddCheckup = async (addName, insertBefore, sortIndex) => {
    try{
      // 找到現有id最大值
      const maxId = await apiService.getMaxID(tableName);

      const index = rows.indexOf(insertBefore);

      if (index !== -1) {
        const newRows = [...rows];
        newRows.splice(index, 0, addName);
        setRows(newRows);
        
        //更新其他項目的排序值，檢查其餘順序sortIndex做加入
        // console.log("現在index",sortIndex)
        newRows.forEach((item, idx) => {
          if(idx >= sortIndex){
              const originalItem = data.find(dataItem => dataItem.name === item);
              if(originalItem){
                const updatedItem = {
                  ...originalItem,
                  sort: originalItem.sort + 1 //後者全部加1
                };
                // 呼叫更新 API
                apiService.updateOne(tableName, { id: updatedItem.id }, updatedItem)
              }
          }
        })
      } else {
        setRows([...rows, addName]);
      }
        //編碼
        const identifierWithUid = await apiService.encode(tableName)
        // console.log(identifierWithUid.identifierWithUid)
  
        //更新 addData 狀態
        const tmp = {
          ...addData,
          id: maxId.Max_ID + 1,
          identifier: identifierWithUid.identifierWithUid, //需改成依照log資料數編碼
          name: addName,
          sort: sortIndex
        };
        setAddData(tmp);
        
        // 資料庫更動=>新增一筆
        const result = await apiService.insertOne(tableName, tmp);
        if (!result.Status) {
          console.error('Failed to update document into + :', result.MSG);
          return;
        }
        // console.log(tableNameMatrix);
        // 新增關係表格
        if(tableNameMatrix){
          // 找到現有關係表id最大值
          const maxMatrixId = await apiService.getMaxID("TR_RelationMatrix");
          //抓出健檢或問卷的item所有id
          const result = await apiService.getMany(tableNameMatrix, {}, null);
          console.log(`${tableNameMatrix} Data:`, result.Data);

          const newRelations = [];
          //新增健檢小項關係表
          if(tableNameMatrix === "TN_SurveyItem")
          result.Data.forEach((surveyItem,index) => {
            let relationTmp = {
              id: index,
              hcItem_id: maxId.Max_ID + 1,
              surveyItem_id: surveyItem.id
            };
            // console.log(maxMatrixId)
              if(maxMatrixId.Max_ID){
                relationTmp.id = maxMatrixId.Max_ID + 1 + index
              }
              newRelations.push(relationTmp);
          });
          //新增問卷小項關係表
          if(tableNameMatrix === "TN_HealthCheckItem"){
            result.Data.forEach((hcItem,index) => {
              let relationTmp = {
                id: index,
                hcItem_id: hcItem.id,
                surveyItem_id: maxId.Max_ID + 1
              };
              // console.log(maxMatrixId)
                if(maxMatrixId.Max_ID){
                  relationTmp.id = maxMatrixId.Max_ID + 1 + index
                }
                newRelations.push(relationTmp);
            });
          }

          newRelations.forEach(relation => {
            console.log(relation);
            apiService.insertOne("TR_RelationMatrix", relation);
          });
          
        }
        // 在新增成功後，重新呼叫 API 獲取最新的資料
        const result2 = await apiService.getMany(tableName, {category_id: category_id}, null);
        if (!result2.Status) {
          console.error('Failed to update document into + :', result.MSG);
          return;
        }
        setData(Array.isArray(result2.Data) ? sortByCategorySort(result2.Data) : []);

    } catch (error) {
      console.error('API request error:', error);
    }
        
  };
  

  const [loading, setLoading] = useState(false); // State for loading indicator

  // 在資料加載完成後設置 loading 為 true
  useEffect(() => {
    isEdit ?
      setTimeout(() => {
        setLoading(true);
      }, 2000)
    :
    setLoading(true);
  }, [isEdit]);

  //------------------------------ drag and drop ------------------------------//
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragIndex", index);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  const handleDrop = async (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("dragIndex");
    const dragItem = rows[dragIndex];
    const newRows = [...rows]
    newRows.splice(dragIndex, 1); // 先從原位置移除
    newRows.splice(dropIndex, 0, dragItem);
    setRows(newRows);
    
    const result = await apiService.getMany(tableName, { category_id: category_id }, null);
    if (!result.Status) {
      console.error('Failed to update document:', result.MSG);
      return;
    }
    
    // 更新排序
    const updatedItems = [];
    newRows.forEach((item, idx) => {
        const originalItem = result.Data.find(dataItem => dataItem.name === item);
        if (originalItem && originalItem.sort !== idx) { //若有元素，元素原順序又和現在不同
            const updatedItem = {
                ...originalItem,
                sort: idx
            };
            updatedItems.push(updatedItem);
        }
    });

    // 同時更新多筆資料
    if (updatedItems.length > 0) {
        await Promise.all(updatedItems.map(async updatedItem => {
            try {
                // 呼叫更新 API
                await apiService.updateOne(tableName, { id: updatedItem.id }, updatedItem);
            } catch (error) {
                console.error('API request error:', error);
            }
        }));
    }

    // 在拖拉成功後，重新呼叫 API 獲取最新的資料
    const result2 = await apiService.getMany(tableName, {category_id: category_id}, null);
    if (!result2.Status) {
      console.error('Failed to update document into + :', result.MSG);
      return;
    }
    setData(Array.isArray(result2.Data) ? sortByCategorySort(result2.Data) : []);
  };

  return (
    <>
    <List sx={{ width: '100%' }}>
      {isEdit && rows.length === 0 && (
        <div className="container_between">
          <div></div>
          <div className="common" onClick={() => handleAddDialogOpen(-1)}>新增項目</div>
        </div>
      )}
      {/* 頂端的新增按鈕 */}
      {isEdit && rows.length > 0 && (
        <React.Fragment>
          <div className='addList_container_top'>
            <AddCircleOutlineIcon className='addList' fontSize="large" onClick={() => handleAddDialogOpen(-1)}/>
          </div>
        </React.Fragment>
      )}
      {[...rows.keys()].map((value, index) => {
        const labelId = `checkbox-list-label-${value}`;

        return ( 
          <ListItem
            key={value}
            disablePadding
            sx={{ p: 0}}
          >
                <ListItemButton 
                  role={undefined} 
                  onClick={toPage && loading ? handleToggle(data[value], toPage) : undefined}
                  // onClick={toPage && loading ? handleToggle(data[value], toPage) : ()=> console.log(data[value])}
                  dense
                  sx={{ mt: 2, borderRadius: '5px', ...(isEdit && { p: 1.45 })}}
                >
                  {loading ?
                  <>
                      {!isEdit ?
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        :
                        <div className="edit_icons_left_empty">

                        </div>
                      }
                      
                      {/* 健檢大項 */}
                      {/* <ListItemText id={labelId} primary={(index+1) + ".　" + rows[value] + "　【" + rowsid[value] + "】"} /> */}
                      <ListItemText id={labelId} primary={(index+1) + ".　" + rows[value]} />
                  </>
                  :
                  <Box style={{ width: '100%', height: '30px'}}>
                    <LinearProgress />
                  </Box>
          
                  }
                  </ListItemButton>

              {isEdit && loading && <>
                <ListItemIcon
                  draggable
                  onDragStart={(e) => handleDragStart(e, value)}
                  onDragOver={(e) => handleDragOver(e)} 
                  onDrop={(e) => handleDrop(e, value)}
                  className="edit_icons_left"
                >
                    <UnfoldMoreIcon className='edit_icon' fontSize="large"/>
                </ListItemIcon>
                <div className='container_flex edit_icons_right'>
                  {/* 更新 */}
                  <ModeIcon className='edit_icon' onClick={() => handleEditDialogOpen(index)}/>
                  {/* 刪除 */}
                  <DeleteIcon className='edit_icon' onClick={() => handleDeleteDialogOpen(index)}/>  
                </div>
              </>
              }

              {/* 新增 */}
              {isEdit && loading && (
                <React.Fragment>
                  <div className='addList_container'>
                    <AddCircleOutlineIcon className='addList' fontSize="large" onClick={() => handleAddDialogOpen(index)}/>
                  </div>
                </React.Fragment>
                )}
          </ListItem>

        )
      })}

      {/* //------------------------------ 新增 Dialog ------------------------------// */}
      <Dialog
        open={addDialogOpen}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const addName = formJson.addName;
            // console.log("上面的項目: "+ rows[index] + "  新增的項目: " + addName);
            handleAddCheckup(addName, rows[index + 1], index+1);
            handleClose();
            setSnackbarMessage('成功新增一筆項目');
            setSuccessSnackbarOpen(true);
          },
        }}
      >
        <DialogTitle>新增檢查類組</DialogTitle>
        <DialogContent>
          <DialogContentText>
            加入一個類組至資料表，名稱不超過10字元。
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="addName"
            label="類組名稱"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </Dialog>
      {/* //------------------------------ 更新 Dialog ------------------------------// */}
      <Dialog
      open={editDialogOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);;
          const formJson = Object.fromEntries(formData.entries());
          const editName = formJson.editName;

          // console.log("更新的項目: " + rows[index] + " 新名稱: " + editName);
          handleEditCheckup(editName, index);
          handleClose();
          setSnackbarMessage('成功更新一筆項目');
          setSuccessSnackbarOpen(true);
          
        },
      }}
      
      >
        <DialogTitle>更新名稱</DialogTitle>
        <DialogContent>
          <DialogContentText>
            編輯檢查類組名稱，名稱不超過10字元。
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="editName"
            label="類組名稱"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </Dialog>
      {/* //------------------------------ 刪除 Dialog ------------------------------// */}
      <Dialog
          open={deleteDialogOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const deleteName = formJson.deleteName;
              // console.log("你刪除元素: "+ rows[index]);
              if(deleteName === rows[index]){
                handleDeleteCheckup(index);
                handleClose();
                setSnackbarMessage('成功刪除一筆項目');
                setSuccessSnackbarOpen(true);
              }else {
                // 如果條件不成立，設置 Snackbar 的狀態並顯示提示訊息
                setSnackbarMessage('刪除失敗，輸入的名稱與選擇的名稱不符合');
                setErrorSnackbarOpen(true);
              }
            },
          }}
      >
        <DialogTitle>刪除檢查類組</DialogTitle>
        <DialogContent>
          <DialogContentText>
            確定要刪除嗎?類組底下的項目將一併刪除
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="deleteName"
            label="類組名稱"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" sx={{color: 'red'}}>Delete</Button>
        </DialogActions>
        {/* 刪除失敗 */}
        <Snackbar 
          open={errorSnackbarOpen} 
          autoHideDuration={6000} 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Dialog>

    </List>

    {/* 新增成功*/}{/* 更新成功 */}{/* 刪除成功 */}
    <Snackbar 
      open={successSnackbarOpen}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      onClose={() => setSuccessSnackbarOpen(false)}
    >
      <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>

    </>
  );
}