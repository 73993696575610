import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';

import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import TextWhite from "../components/inputs/TextWhite";
import Select from "../components/dropdowns/Select";
import Dropdowns from "../components/dropdowns/Dropdowns";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

//api
import apiService from "../services/apiService";

const AddRelation = (props) => {
    const [mainWidth, setMainWidth] = useState("250px");
    const { target, setTarget } = useFileContext();
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const location = useLocation();
    // console.log(location)
    const [data, setData] = useState(() => {
        const initialState = {
            relationName: "",
        };
        // 檢查 location.state 和 location.state.data 是否存在，並避免讀取 null 或 undefined 的屬性
        if (location.state && location.state.data) {
            return {
                ...initialState,
                relationName: location.state.data.relationName || initialState.relationName,
            };
        } else {
            return initialState;
        }
    });
    

    const handleSelectionChange = (value) => {
        setData(prevState => ({
            ...prevState,
            relationName: value
        }));
    };

    location.state = { ...location.state, data: data }; //暫存
        
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option, checkInputIsEmpty) => {
        if(checkInputIsEmpty === true && (option === "/AddRelation" || option === "/HealthCheck" || option === "/Survey")){
            if(location.state && location.state.data.relationName !== ""){
                navigate(option, { state: location.state });
            } else{
                setSuccessSnackbarOpen(true);
            }
        }
        else{
            navigate(option, { state: null });
        }
    };
    
    
    const [categoryHC_id, setCategoryHC_id] = useState();
    const [getHCData, setGetHCData] = useState([]);
    const [getHCIData, setGetHCIData] = useState([]);
    
    const [categorySC_id, setCategorySC_id] = useState();
    const [getSCData, setGetSCData] = useState([]);
    const [getSIData, setGetSIData] = useState([]);
    

    useEffect(() => {
        // 排序資料
        const sortBySort = (data) => {
            return data.sort((a, b) => a.sort - b.sort);
        };
        // 排序資料(小項目)
        const sortByCategorySort = (data) => {
            return data.sort((a, b) => {
                // 先按照 category_id 排序
                if (a.category_id !== b.category_id) {
                    return a.category_id - b.category_id;
                }
                // 如果 category_id 相同，再按照 sort 排序
                return a.sort - b.sort;
            });
        };
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                if(collection_name === 'TN_HealthCheckCategory'){
                    setState(Array.isArray(result.Data) ? sortBySort(result.Data) : []);
                } else{
                    setState(Array.isArray(result.Data) ? sortByCategorySort(result.Data) : []);
                }
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TN_HealthCheckCategory', {}, setGetHCData),
                fetchManyData('TN_HealthCheckItem', {}, setGetHCIData),
                fetchManyData('TN_SurveyCategory', {}, setGetSCData),
                fetchManyData('TN_SurveyItem', {}, setGetSIData)
            ]);
        };

        fetchAllData();

        
    },[categoryHC_id, categorySC_id]);
    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="row_small">
                        <h1 className="maintitle">問卷和健檢表建立工具</h1>
                        <h2 className="subtitle container"><span>建立新關係表</span> <KeyboardDoubleArrowRightIcon /> 選擇健檢項目 <KeyboardDoubleArrowRightIcon /> 選擇問卷項目</h2>
                    </div>

                    <h2 className="subtitle">STEP 1　表格資訊</h2>
                    
                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">請輸入資料表名稱:</label>
                        <TextWhite //輸入格
                            placeholder={"ex: 糖管理相關"}
                            onChange={(value) => handleSelectionChange(value)}
                            value={data['relationName']}
                        />
                    </div>
                    
                    <div className="row_small">
                        <h2 className="subtitle">STEP 2　選擇獨立變量或直接生成表格</h2>
                        <h3 className="h3subtitle">選擇項目成獨立變量，與表格健檢項目、問卷項目沒有直接關係</h3>
                    </div>
                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">選擇檢查項目獨立變量:</label>
                        <div className="container">
                            <Select data={getHCData} category_id={categoryHC_id || ''} setCategory_id={setCategoryHC_id} noStyle={true}/>
                            <Dropdowns data={getHCIData} locationClass={"chooseHC"} category_id={categoryHC_id || 0} target={target} setTarget={setTarget}/>
                        </div>
                    </div>

                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">選擇問卷獨立變量:</label>
                        <div className="container">
                            <Select data={getSCData} category_id={categorySC_id || ''} setCategory_id={setCategorySC_id} noStyle={true}/>
                            <Dropdowns data={getSIData} locationClass={"chooseSurvey"}  category_id={categorySC_id || 0} target={target} setTarget={setTarget}/>
                        </div>
                    </div>

                    <div className="container_between">
                        <div className="common" onClick={()=> changePage(target.previousPage)}>Cancel</div>
                        <div className="common" onClick={()=> changePage("/HealthCheck", true)}>Next</div>
                    </div>
                    
                    <Snackbar 
                        open={successSnackbarOpen} 
                        autoHideDuration={6000} 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        onClose={() => setSuccessSnackbarOpen(false)}>
                        <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setSuccessSnackbarOpen(false)}>
                            未填寫表格名稱
                        </MuiAlert>
                    </Snackbar>

                </div>
            </main>
        </>
    );
};

export default AddRelation;

