import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import RelationTable from "../components/table/RelationTable";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ClearIcon from '@mui/icons-material/Clear';

//dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//api
import apiService from "../services/apiService";

const RelationFolder = () => {
    const [mainWidth, setMainWidth] = useState("250px");
    const { target, setTarget, chooseTargets, setChooseTargets } = useFileContext();

    const [ chooseAddTargets, setChooseAddTargets ] = useState([])
    const [ openOtherData, setOpenOtherData ] = useState(false)
    const [check, setCheck] = useState(false)
    const [selectedDelete, setSelectedDelete] = useState([]);
    // console.log("選擇刪除項目",selectedDelete)
    const [selectedAdd, setSelectedAdd] = useState([]);
    // console.log("選擇加入項目",selectedAdd)
    
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option, { state: null }); // 將 location.state 設置為 null
        setTarget("/RelationFolder");
    };
    const [getData, setGetData] = useState([]);

    const [getUserData, setGetUserData] = useState([]);
    const [getOtherData, setGetOtherData] = useState([]);
    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? result.Data : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TR_Relation', {relationFolder_id: target.target.id}, setGetData),
                fetchManyData('TR_User', {}, setGetUserData),
                fetchManyData('TR_Relation', {"relationFolder_id": 0}, setGetOtherData),
            ]);
        };

        fetchAllData();

        // 新增的判斷條件
        if (check === false) {
            setSelectedDelete([]);
            setChooseTargets([]);
        }
        if(openOtherData === false){
            setSelectedAdd([])
            setChooseAddTargets([]);
        }
    },[check, openOtherData]);

    //取消刪除
    const cancelDelete = () =>{
        setCheck(!check)
        setChooseTargets([])
    }

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const handleDeleteDialogOpen = (index) => {
        setDeleteDialogOpen(true);
    };
    const [addDialogOpen, setAddDialogOpen] = React.useState(false);
    const handleAddDialogOpen = (index) => {
        setAddDialogOpen(true);
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setAddDialogOpen(false);
    };

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(''); 

    //------------------------------ 刪除 ------------------------------//
    const handleDelectCheckup = async () => {
        const updatedItem = chooseTargets.map(item => ({
            ...item,
            relationUpdateTime: new Date(),
            relationFolder_id: 0
        }));
        // 資料庫更動=>刪除
        try {
            //更新多個關係表
            for(let item of updatedItem){
                const updateResult = await apiService.updateOne("TR_Relation", {id: item.id}, item);
                if(!updateResult.Status){
                    console.error(`Failed to update relation with ${item.id}:`, updateResult.MSG);
                }
            }
            //清除暫存資料
            setCheck(!check)

            setSuccessSnackbarOpen(true);
            setSnackbarMessage('成功刪除項目');
            cancelDelete();
            handleClose();

        } catch (error) {
            console.error('API request error:', error);
        }
        
    };

    //------------------------------ 新增 ------------------------------//
    const handleAddCheckup = async () => {
        const updatedItem = chooseAddTargets.map(item => ({
            ...item,
            relationUpdateTime: new Date(),
            relationFolder_id: target.target.id
        }));
        // 資料庫更動
        try {
            //更新多個關係表
            for(let item of updatedItem){
                const updateResult = await apiService.updateOne("TR_Relation", {id: item.id}, item);
                if(!updateResult.Status){
                    console.error(`Failed to update relation with ${item.id}:`, updateResult.MSG);
                }
            }

            //清除暫存資料
            setChooseAddTargets([]);
            setOpenOtherData(!openOtherData)

            setSuccessSnackbarOpen(true);
            setSnackbarMessage('成功新增項目');
            handleClose();

        } catch (error) {
            console.error('API request error:', error);
        }
        
    };

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="row_small">
                        <h1 className="maintitle">關係表列表</h1>
                        <h2 className="subtitle container">項目列表 <KeyboardDoubleArrowRightIcon /> <span>{target.target.name}</span></h2>
                    </div>
                    {getData.length !== 0 && 
                        <RelationTable data={getData} folderData={"inFolderPage"} userData={getUserData} 
                            isEdit={false} 
                            isCheck={check}
                            target={target} setTarget={setTarget}
                            setChooseTargets={setChooseTargets}
                            selected={selectedDelete} setSelected={setSelectedDelete}
                        />
                    }
                    <div className="container_between">
                        <div className="container_flex">
                            <div className="common" onClick={()=> changePage(target.previousPage)}>Previous</div>
                            {getData.length !== 0 && 
                                <div className="common" onClick={()=>cancelDelete()}>
                                    {!check ? "刪除關係表" 
                                    : <>
                                        <ClearIcon/>取消刪除
                                    </>}
                                </div>
                            }
                            {chooseTargets.length !== 0 &&
                                <div className="common final_red" onClick={()=>handleDeleteDialogOpen()}>刪除所選關係表</div>
                            }
                        </div>
                        <div className="container_flex">
                            {getOtherData.length !== 0 &&
                            <div className="common" onClick={()=> setOpenOtherData(!openOtherData)}>
                                {openOtherData ? 
                                <>
                                    <ClearIcon/>取消新增
                                </>
                                : "加入關係表"}
                            </div>
                            }
                            {chooseAddTargets.length !== 0 &&
                                <div className="common final" onClick={()=>handleAddDialogOpen()}>新增所選關係表</div>
                            }
                        </div>
                    </div>
                    {openOtherData && getOtherData.length !== 0 &&
                    <>
                        <RelationTable data={getOtherData} folderData={"inFolderPage"} userData={getUserData} 
                            isEdit={false} 
                            isCheck={true}
                            setTarget={setTarget}
                            setChooseTargets={setChooseAddTargets}
                            selected={selectedAdd} setSelected={setSelectedAdd}
                        />
                    </>
                    }
                </div>


                {/* 刪除確認dialog */}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleDelectCheckup();
                    },
                    }}
                >
                    <DialogTitle>刪除所選關係表</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            確定要刪除 " {target.target.name} " 裡的資料?
                        </DialogContentText>               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" sx={{color: 'red'}}>Delete</Button>
                    </DialogActions>
                </Dialog>
                {/* 新增確認dialog */}
                <Dialog
                    open={addDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleAddCheckup();
                    },
                    }}
                >
                    <DialogTitle>新增所選關係表</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            確定要新增資料至 " {target.target.name} "?
                        </DialogContentText>               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
                {/* 成功刪除;成功新增 */}
                <Snackbar 
                    open={successSnackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                    }}
                    onClose={() => setSuccessSnackbarOpen(false)}
                >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </main>

        </>
    );
};
export default RelationFolder;