import React, {useEffect} from "react";
import { useSidebar } from "../context/SidebarContext";
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ setMainWidth }) => {
    const { isOpen, toggleSidebar, isLoggedIn, userPermissions } = useSidebar();
    useEffect(() => {
        setMainWidth(isOpen ? "250px" : "0");
    }, [isOpen, setMainWidth]);

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
    };

    //判定不為登入狀態
    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/"); // 跳轉到登入頁面
        }
    }, [isLoggedIn, navigate]);

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar_hover-btn" onClick={toggleSidebar}></div>
        <div className="sidebar_title">Relation Builder</div>
        <ul>
            <li className="li_title">關係表</li>
            <li onClick={() => changePage("/Relation")}>關係表列表</li>
            <li onClick={() => changePage("/HeatMap")}>熱圖列表</li>
            {userPermissions === 1 && <>
                <li className="li_title">關係表編輯</li>
                <li onClick={() => changePage("/HealthCheckEdit")}>健檢項目管理</li>
                <li onClick={() => changePage("/SurveyEdit")}>問卷項目管理</li>
                <li className="li_title">權限</li>
                <li onClick={() => changePage("/UserList")}>人員列表</li>
            </>
            } 
        </ul>
        </div>
    );
}

export default Sidebar;


