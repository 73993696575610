import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//登入
import Signup from "./pages/login/1_Signup";
import SetPwd from "./pages/login/2_SetPwd";
import ForgetPwd from "./pages/login/3_ForgetPwd.js";
import ResetPwd from "./pages/login/4_ResetPwd.js";
import Relation from "./pages/1_Relation.js";
import RelationFolder from "./pages/1.1_RelationFolder";
import RelationItemEdit from "./pages/1.2_RelationItemEdit.js";
import AddRelation from "./pages/2_AddRelation.js";
import HealthCheck from "./pages/2.1_HealthCheck.js";
import Survey from "./pages/2.2_Survey.js";
import HeatMap from "./pages/3_HeatMap.js";
import HeatMapFolder from "./pages/3.1_HeatMapFolder.js";
import HeatMapItem from "./pages/3.1_HeatMapItem.js";
import HealthCheckEdit from "./pages/4_HealthCheckEdit.js";
import HealthCheckItemEdit from "./pages/4.1_HealthCheckItemEdit.js";
import SurveyEdit from "./pages/5_SurveyEdit.js";
import SurveyItemEdit from "./pages/5.1_SurveyItemEdit.js";
import UserList from "./pages/6_UserList.js";
import User from "./pages/7_User.js";

import { SidebarProvider } from "./context/SidebarContext";
import { FileProvider } from "./context/FileContext.jsx"

function App() {
  
    return (
      <>
        <Router>
          <Routes>
            {/*------------------------ 登入 ------------------------*/}
            <Route path="/" element={<SidebarProvider><Signup /></SidebarProvider>} />
            <Route path="/SetPwd" element={<SidebarProvider><SetPwd /></SidebarProvider>} />
            <Route path="/ForgetPwd" element={<SidebarProvider><ForgetPwd /></SidebarProvider>} />
            <Route path="/ResetPwd" element={<SidebarProvider><ResetPwd /></SidebarProvider>} />
            <Route path="/Relation" element={<SidebarProvider><FileProvider><Relation /></FileProvider></SidebarProvider>} />
            <Route path="/RelationFolder" element={<SidebarProvider><FileProvider><RelationFolder /></FileProvider></SidebarProvider>} />
            <Route path="/RelationItemEdit" element={<SidebarProvider><FileProvider><RelationItemEdit /></FileProvider></SidebarProvider>} />
            {/* <Route path="/AddRelation" element={<SidebarProvider><FileProvider><AddRelation /></FileProvider></SidebarProvider>} />
            <Route path="/HealthCheck" element={<SidebarProvider><FileProvider><HealthCheck /></FileProvider></SidebarProvider>} />
            <Route path="/Survey" element={<SidebarProvider><FileProvider><Survey /></FileProvider></SidebarProvider>} /> */}
            <Route path="/HeatMap" element={<SidebarProvider><FileProvider><HeatMap /></FileProvider></SidebarProvider>} />
            <Route path="/HeatMapFolder" element={<SidebarProvider><FileProvider><HeatMapFolder /></FileProvider></SidebarProvider>} />
            <Route path="/HeatMapItem" element={<SidebarProvider><FileProvider><HeatMapItem /></FileProvider></SidebarProvider>} />
            <Route path="/HealthCheckEdit" element={<SidebarProvider><HealthCheckEdit /></SidebarProvider>} />
            <Route path="/HealthCheckItemEdit" element={<SidebarProvider><HealthCheckItemEdit /></SidebarProvider>} />
            <Route path="/SurveyEdit" element={<SidebarProvider><SurveyEdit /></SidebarProvider>} />
            <Route path="/SurveyItemEdit" element={<SidebarProvider><SurveyItemEdit /></SidebarProvider>} />
            <Route path="/UserList" element={<SidebarProvider><UserList /></SidebarProvider>} />
            <Route path="/User" element={<SidebarProvider><User /></SidebarProvider>} />

          
          </Routes>
        </Router>
      </>
    )
}

export default App










