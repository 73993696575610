import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../context/SidebarContext';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import TextWhite from "../components/inputs/TextWhite";
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

//api
import apiService from "../services/apiService";

const User = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    const { userId, protectEyesMode, toggleProtectEyesMode } = useSidebar();

    const [data, setData] = useState({
        userIdentifier: '',
        userAccount: '',
        userName: '',
        userPassword: '',
        userPreference: '',
        userPermissions: '',
    });

    const handleSelectionChange = (field, value) => {
        setData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    //密碼驗證
    const [disabled, setDisabled] = useState(true)
    const pwdVerify = (value) => {
        if(value === data.userPassword){
            setSnackbarMessage('密碼輸入正確，可進行密碼更新');
            setSuccessSnackbarOpen(true);
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    };

    //改變密碼
    const [newPassword, setNewPassword] = useState("");
    const handleChangePwd = (value) =>{
        setNewPassword(value);
    }

    const TL_PermissionLevel = [
        { id: 0, label: "待選取" },
        { id: 1, label: "最高管理權限" }, //後台人員(創建者)
        { id: 2, label: "設定權限" },
        { id: 3, label: "基礎權限" }, //醫師
    ];

    const getPermissionLevelLabel = (id) => {
        const level = TL_PermissionLevel.find(level => level.id === id);
        return level ? level.label : '未知權限';
    };

    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    
    const handleEditDialogOpen = () => {
        setEditDialogOpen(true);
    };

    const handleClose = () => {
        setEditDialogOpen(false);
    };

  //------------------------------ 更新 ------------------------------//
    const handleEditCheckup = () => {
        const originalItem = data;
        const updatePwd = newPassword? newPassword : data.userPassword;
        // const 
        const updatedItem = {
            ...originalItem,
            userPassword: updatePwd,
            userUpdateTime: new Date(),
        };
        apiService.updateOne("TR_User", { id: userId }, updatedItem)
        .then(() => {
            // 更新成功後重新加載頁面
            window.location.reload();
        })
        .catch(error => {
            console.error('API request error:', error);
        });

    };

    //------------------------------ 深色模式 ------------------------------//
    const [checked, setChecked] = useState(false);
    if(protectEyesMode !== checked){
        toggleProtectEyesMode();
    }
    const handleChangeState = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        setData(prevState => ({
            ...prevState,
            userPreference: isChecked ? 1 : 0
        }));
        toggleProtectEyesMode();
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await apiService.getOne("TR_User",{"id": Number(userId)}, null);

                if (result.Status) {
                    setData({
                        userIdentifier: result.Data.userIdentifier,
                        userAccount: result.Data.userAccount,
                        userName: result.Data.userName,
                        userPassword: result.Data.userPassword,
                        userPreference: result.Data.userPreference,
                        userPermissions: result.Data.userPermissions
                    });
                    setChecked(result.Data.userPreference === 1);
                } else {
                    throw new Error("Failed to fetch health check data for userId");
                }

            } catch (error) {
                console.error("Error fetching relation data:", error);
            }
        };
        
        fetchData();
    }, []);

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">帳戶資訊</h1>
                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">使用者唯一碼 :</label>
                        <TextWhite //輸入格
                            onChange={(value) => handleSelectionChange('userIdentifier', value)}
                            value={data.userIdentifier}
                            disabled={true}
                        />
                    </div>

                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">使用者名稱 :</label>
                        <TextWhite //輸入格
                            placeholder={"請輸入名字"}
                            onChange={(value) => handleSelectionChange('userName', value)}
                            value={data.userName}
                        />
                    </div>
                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">信箱(帳號) :</label>
                        <TextWhite //輸入格
                            placeholder={"請輸入聯絡信箱"}
                            onChange={(value) => handleSelectionChange('userAccount', value)}
                            value={data.userAccount}
                        />
                    </div>
                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">密碼 : <span>原密碼驗證成功後可更新密碼</span></label>
                        <TextWhite //輸入格
                            pwd={true}
                            placeholder={"請輸入原密碼"}
                            onChange={(value) => pwdVerify(value)}
                        />
                        {!disabled && 
                        <TextWhite //輸入格
                            pwd={true}
                            disabled={disabled}
                            placeholder={"請輸入新密碼"}
                            onChange={(value) => handleChangePwd(value)}
                        />
                        }
                    </div>

                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">帳戶權限 :</label>
                        <TextWhite //輸入格
                            onChange={(value) => handleSelectionChange('userPermissions', value)}
                            value={getPermissionLevelLabel(data.userPermissions)}
                            disabled={true}
                        />
                    </div>

                    <div className="row_small">
                        <label className="dropdown_label" htmlFor="">外觀 :</label>
                        <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <Switch 
                            checked={checked} 
                            onChange={handleChangeState} 
                            />
                        }
                        label={checked ? "深色模式" : "淺色模式"}
                        />
                    </div>

                    <div className="container_between">
                        <div></div>
                        <div className="common final" onClick={handleEditDialogOpen}>Update and Save</div>
                    </div>

                    {/* //------------------------------ 更新 Dialog ------------------------------// */}
                    <Dialog
                    open={editDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                        event.preventDefault();
                        // console.log("更新的項目: " + rows[index] + " 新名稱: " + editName);
                        handleEditCheckup();
                        handleClose();
                        setSnackbarMessage('成功更新一筆項目');
                        setSuccessSnackbarOpen(true);
                        
                        },
                    }}
                    
                    >
                        <DialogTitle>確定要更新嗎?</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Update</Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar 
                    open={successSnackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    onClose={() => setSuccessSnackbarOpen(false)}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                    </Snackbar>

                </div>
            </main>
        </>
    );
};

export default User;