import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import Table from "../components/table/UserTable";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//api
import apiService from "../services/apiService";

const UserList = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    const [getData, setGetData] = useState([]);
    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? result.Data : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TR_User', {}, setGetData),
            ]);
        };

        fetchAllData();
    },[]);


    const [addDialogOpen, setAddDialogOpen] = React.useState(false);
    const handleAddDialogOpen = (index) => {
        setAddDialogOpen(true);
    };

    const handleClose = () => {
    setAddDialogOpen(false);
    };



    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const [data, setData] = useState(
        {
            id: 0,
            userIdentifier: 0,
            userAccount: "",
            userPassword: "",
            userName: "",
            userCreateTime: new Date(),
            userUpdateTime: new Date(),
            userState: 0,
            userPreference: 0, //使用者偏好
            userPermissions: 3
        }
    ) ;

//------------------------------ 新增 ------------------------------//
const handleAddCheckup = async (name, email) => {
    // 找到現有id最大值
    const maxId = await apiService.getMaxID("TR_User");
    //編碼
    const identifierWithUid = await apiService.encode("TR_User")
    const tmp = {
        ...data,
        id: maxId.Max_ID + 1,
        userIdentifier: identifierWithUid.identifierWithUid,
        userAccount: email,
        userPassword: "pwd_867745", //預設密碼
        userName: name,
    };
    setData(tmp);
    
    // 資料庫更動=>新增一筆
    try {
        const result = await apiService.sendEmail( tmp.userAccount );
        //判定有無信箱
        if(result.status){
            setSuccessSnackbarOpen(true);
            setSnackbarMessage('成功新增一筆項目');
            const result = await apiService.insertOne("TR_User", tmp)
            .then(() => {
                // 更新成功後重新加載頁面
                window.location.reload();
            })
            .catch(error => {
                console.error('API request error:', error);
            });
            if (!result.status) {
                console.error('Failed to update document into + :', result.MSG);
                return;
            }
        }else{
            setErrorSnackbarOpen(true);
            setSnackbarMessage('新增錯誤，無此信箱');
        }
    } catch (error) {
        console.error('API request error:', error);
    }
    
  };

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">人員列表</h1>
                    <Table data={getData} isEdit={true}/>

                    <div className="container_between">
                        <div></div>
                        <div className="common" onClick={()=>handleAddDialogOpen()}>新增帳戶</div>
                    </div>
                </div>

                {/* 新增 */}
                <Dialog
                    open={addDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const name = formJson.name;
                        const gmail = formJson.gmail;

                        handleAddCheckup(name, gmail);

                    },
                    }}
                >
                    <DialogTitle>新增帳戶</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            將新用戶通知內容傳送至指定信箱。
                        </DialogContentText>

                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="name"
                            label="使用者名稱"
                            fullWidth
                        />

                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="gmail"
                            label="gmail"
                            fullWidth
                        />                    
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>

                    <Snackbar 
                        open={successSnackbarOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                        }}
                        onClose={() => setSuccessSnackbarOpen(false)}
                    >
                        <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                            {snackbarMessage}
                        </MuiAlert>
                    </Snackbar>

                    <Snackbar 
                        open={errorSnackbarOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                        }}
                        onClose={() => setErrorSnackbarOpen(false)}
                    >
                        <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setSuccessSnackbarOpen(false)}>
                            {snackbarMessage}
                        </MuiAlert>
                    </Snackbar>

                </Dialog>

            </main>
        </>
    );
};

export default UserList;