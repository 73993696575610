import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';
import { useSidebar } from '../context/SidebarContext';

import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import ListControls from "../components/list/ListControls";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';

// import LinearProgressWithLabel from "../components/wait/LinearProgressWithLabel"
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

//api
import apiService from "../services/apiService";

const HealthCheck = (props) => {
    const [mainWidth, setMainWidth] = useState("250px");

    const { userId } = useSidebar();

    // 使用 useNavigate 鉤子進行路由導航
    const location = useLocation();
    // console.log(location);
    const navigate = useNavigate();
    const changePage = (option) => {
        if (option === "/AddRelation" || option === "/HealthCheck" || option === "/Survey") {
            // 如果跳轉到 "/AddRelation", "/HealthCheck" 或 "/Survey" 頁面，保留暫存資料
            navigate(option, { state: { ...location.state } });
        } else {
            // 如果跳轉到其他頁面，清除暫存資料
            navigate(option, { state: null });
        }
    };

    const [openMask, setOpenMask] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const { target, chooseList, checked, setChecked } = useFileContext();
    const handleClickOpen = async () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    
    const [getHCData, setGetHCData] = useState([]);
    useEffect(() => {
        // 排序資料
        const sortByCategorySort = (data) => {
            return data.sort((a, b) => a.sort - b.sort);
        };
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                // setState(Array.isArray(result.Data) ? result.Data : []);
                setState(Array.isArray(result.Data) ? sortByCategorySort(result.Data) : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TN_HealthCheckCategory', {}, setGetHCData),
            ]);
        };

        fetchAllData();

    },[]);

    //------------------------------ 新增 ------------------------------//
    const [chooseIDListChecked, setChooseIDListChecked] = useState([]); //最後儲存的id//非常相關
    const [chooseIDListIndeterminate, setChooseIDListIndeterminate] = useState([]); //最後儲存的id//中間相關

    const [loading, setLoading] =  useState(false);
    const [progress, setProgress] = React.useState(0); //進度
    // const handleAddCheckup = async () => {
    //     try {
    //         // 計算循環總長度
    //         const totalIterations = chooseList.length;
    //         let currentIteration = 0;

    //         let checkedIDs = [];
    //         let indeterminateIDs = [];

    //         setLoading(true)
    //         for (const [x, y, status] of chooseList) {
    //             const result = await apiService.getMany("TR_RelationMatrix", {'hcItem_id': x, 'surveyItem_id': y}, null);
    //             const ids = result.Data.map(item => item.id);

    //             // 提取每個物件中的 id 屬性並添加到 chooseIDList 中
    //             if (status === 1) {
    //                 checkedIDs.push(...ids);
    //             } else {
    //                 indeterminateIDs.push(...ids);
    //             }
    //             // setChooseIDList(prevIDList => [...prevIDList, ...result.Data.map(item => item.id)]);

    //             // 更新進度
    //             currentIteration++;
    //             const progress = (currentIteration / totalIterations) * 100;
    //             setProgress(progress);
    //         }

    //         if (chooseList.length === 0) {
    //             setProgress(100);
    //         }
    //         setChooseIDListChecked(checkedIDs);
    //         setChooseIDListIndeterminate(indeterminateIDs);

    //         setLoading(false);
    //         handleClose();
    
    //         // 在所有異步操作完成後設置 Snackbar 訊息並跳轉頁面
    //         setOpenMask(true);
    //         setTimeout(() => {
    //             changePage("/Relation");
    //         }, 3000);
    

    
    //     } catch (error) {
    //         console.error('API request error:', error);
    //     }
    // };
    const handleAddCheckup = async () => {
        try {
            setLoading(true)
            const response = await apiService.saveTRRelationMatrix(chooseList);

            if (response.Status) {
                setChooseIDListChecked(response.checked_ids);
                setChooseIDListIndeterminate(response.indeterminate_ids);

            } else {
                console.error('Server error:', response.MSG);
            }
    
            // 在API請求完成後設置進度為100%
            setProgress(100);

            setLoading(false);
            handleClose();
    
            // 在所有異步操作完成後設置 Snackbar 訊息並跳轉頁面
            setOpenMask(true);
            setTimeout(() => {
                changePage("/Relation");
            }, 3000);
    
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    
    useEffect(() => {
        const finishAddData  = async()=>{
            // 找到現有id最大值
            const maxId = await apiService.getMaxID("TR_Relation");
            //編碼
            const identifierWithUid = await apiService.encode("TR_Relation")
            const tmp = {
                id: maxId.Max_ID + 1,
                user_id: userId, //由SidebarContext登入者資訊取得
                userEdit_id: userId,
                relationFolder_id: 0,
                relationIdentifier: identifierWithUid.identifierWithUid, //需改成依照log資料數編碼,
                relationName: location.state.data.relationName,
                chooseList_checked: chooseIDListChecked,
                chooseList_indeterminate: chooseIDListIndeterminate,
                chooseHC_IV : target.chooseHC ? target.chooseHC : [],
                chooseSurvey_IV: target.chooseSurvey ? target.chooseSurvey : [],
                relationCreateTime: new Date(),
                relationUpdateTime: new Date(),
            };
            // console.log("更新一筆 ", tmp);
            // 資料庫更動=>新增一筆
            const result = await apiService.insertOne("TR_Relation", tmp);
            if (!result.Status) {
                console.error('Failed to update document into + :', result.MSG);
                return;
            }

            setProgress(0);
        }
        if (progress === 100) {
            finishAddData();
        }
    }, [progress]);

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="row_small">
                        <h1 className="maintitle">問卷和健檢表建立工具</h1>
                        <h2 className="subtitle container"> 建立新關係表 <KeyboardDoubleArrowRightIcon /> <span>選擇健檢項目</span> <KeyboardDoubleArrowRightIcon /> 選擇問卷項目</h2>
                        <h3 className="h3subtitle">勾選表示該項目已經進行過編輯</h3>
                    </div>
                        <h3 className="h3subtitle"><span>現有 {getHCData.length} 個項目</span></h3>
                    <ListControls 
                        data={getHCData} toPage={"/Survey"} location={location}
                        checked={checked} setChecked={setChecked} 
                    />
                </div>

                <div className="container_between">
                    <div className="common" onClick={()=> changePage("/AddRelation")}>Previous</div>
                    <div className="common final" onClick={handleClickOpen}>Update and Save</div>
                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {loading ? "資料加入請稍等..." : "確認最終更新並儲存建立關係表?"}
                    </DialogTitle>
                    <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        {"儲存的關係表往後可在編輯功能中進行編輯。"}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        {/* 顯示等待動畫的地方 */}
                        {/* {loading ? 
                        <>
                            <LinearProgressWithLabel progress={progress} />
                        </>
                        :
                        <> */}
                            <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={() => {
                                handleAddCheckup(); 
                            }} 
                            autoFocus 
                            sx={{color: 'red'}}
                            >
                                Agree
                            </Button>
                        {/* </>
                        } */}
                    
                    </DialogActions>


                </Dialog>

            </main>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openMask}
                onClick={handleClose}
            >
                <Stack gap={1} justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
                <Typography>Loading...</Typography>
                </Stack>
            </Backdrop>
            <Snackbar 
                open={successSnackbarOpen} 
                autoHideDuration={6000} 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                onClose={() => setSuccessSnackbarOpen(false)}>
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                {snackbarMessage}
                </MuiAlert>

            </Snackbar>

        </>
    );
};

export default HealthCheck;