import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import RelationTable from "../components/table/RelationTable";

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

//api
import apiService from "../services/apiService";

const HeatMapFolder = () => {
    const [mainWidth, setMainWidth] = useState("250px");
    const { target, setTarget, setChooseTargets } = useFileContext();
    const [selected, setSelected] = useState([]);
    
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option); // 將 location.state 設置為 null
        setTarget({ previousPage: "/HeatMapFolder", target: target.target})
    };
    const [getData, setGetData] = useState([]);
    const [getUserData, setGetUserData] = useState([]);
    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? result.Data : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TR_Relation', {relationFolder_id: target.target.id}, setGetData),
                fetchManyData('TR_User', {}, setGetUserData)
            ]);
        };

        fetchAllData();

        setChooseTargets([]);
    },[]);

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="row_small">
                        <h1 className="maintitle">熱圖列表</h1>
                        <h2 className="subtitle container">項目列表 <KeyboardDoubleArrowRightIcon /> <span>{target.target.name}</span></h2>
                    </div>
                    {getData.length !== 0 ?
                        <RelationTable data={getData} userData={getUserData} 
                            isHeatMapCheck={true}
                            target={target} setTarget={setTarget}
                            setChooseTargets={setChooseTargets}
                            selected={selected} setSelected={setSelected}
                        />
                    :
                        <TableContainer component={Paper} className="">
                            <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="subtitle">尚無關係表</div>
                            </Box>
                        </TableContainer>
                    }
                    <div className="container_between">
                        <div className="common" onClick={()=> changePage('/HeatMap')}>Previous</div>
                            
                        {selected.length !== 0 &&
                            <div className="common" onClick={()=> changePage("/HeatMapItem")}>生成熱圖</div>
                        }
                    </div>
                </div>
            </main>

        </>
    );
};
export default HeatMapFolder;