import React, { useEffect, useRef, useState } from 'react';
import { useSidebar } from "../../context/SidebarContext";
import ReactApexChart from 'react-apexcharts';

const ApexChart = (props) => {
  const chartRef = useRef(null);
  const { protectEyesMode } = useSidebar();
  const [options, setOptions] = useState(getChartOptions(window.innerWidth, protectEyesMode));
  const [series, setSeries] = useState(generateSeries(props.surveyData, props.healthCheckData));

  useEffect(() => {
    const handleResize = () => {
      const width = chartRef.current ? chartRef.current.offsetWidth : window.innerWidth;
      setOptions(getChartOptions(width, protectEyesMode));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [protectEyesMode]);

  useEffect(() => {
    setSeries(generateSeries(props.surveyData, props.healthCheckData));
  }, [props.surveyData, props.healthCheckData]);

  function getChartOptions(width, protectEyesMode) {
    return {
      chart: {
        height: 350,
        type: 'heatmap',
        background: protectEyesMode ? '#000' : '#fff', // 深色模式背景
      },
      dataLabels: {
        enabled: width >= 930,
        style: {
          colors: ['#000'], // 數據標籤顏色
        },
        // formatter: function (val) {
        //   return val.toFixed(2); // 格式化數據標籤為兩位小數
        // },
      },
      colors: ["#1768c8"],
      title: {
        text: 'HeatMap Chart (Single color)',
        style: {
          color: protectEyesMode ? '#fff' : '#000', // 深色模式標題顏色
        },
      },
      stroke: {
        width: 2, // 設置格線寬度
        colors: protectEyesMode ? ['#000'] : ['#fff'], // 設置格線顏色
      },
      xaxis: {
        labels: {
          style: {
            colors: protectEyesMode ? '#fff' : '#000', // 深色模式 x 軸標籤顏色
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: protectEyesMode ? '#fff' : '#000', // 深色模式 y 軸標籤顏色
          },
        },
      },
      tooltip: {
        theme: protectEyesMode ? 'dark' : 'light', // 深色模式工具提示主題
        style: {
          fontSize: '15px',  // 設置工具提示文字大小
        },
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          distributed: false,
        },
      },
    };
  }

  function generateSeries(surveyData, healthCheckData) {
    return surveyData.map(item => ({
      name: item.name,
      data: generateData(healthCheckData, item),
    }));
  }

  function generateData(hcItems, surveyItem) {
    let index = 0;
    const series = [];
    while (index < hcItems.length) {
      const x = hcItems[index].name.toString();
      const y = hcItems[index].weight + surveyItem.weight;
      series.push({ x, y });
      index++;
    }
    // Sort series based on y value in descending order
    series.sort((a, b) => b.y - a.y);
    return series;
  }

  return (
    <div ref={chartRef}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="heatmap" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
