import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../context/SidebarContext';
import { useFileContext } from '../context/FileContext';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import MuiGroupBtn from "../components/buttons/MuiGroupBtn";
import RelationTable from "../components/table/RelationTable";
import RelationFolderTable from "../components/table/RelationFolderTable";

import ClearIcon from '@mui/icons-material/Clear';

//dialog
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

//api
import apiService from "../services/apiService";

const Relation = () => {
    const [mainWidth, setMainWidth] = useState("250px");
    const { userId } = useSidebar();
    const { resetParameters , //重設參數
            target, setTarget, chooseTargets,
            setChooseTargets } = useFileContext();

    const [btn, setBtn] = useState(1);
    const [check, setCheck] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        // 當頁面加載時清除資料
        resetParameters();
    }, []);
    
    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option, { state: null }); // 將 location.state 設置為 null
        setTarget({previousPage:"/Relation"});
    };

    const [getRelationData, setGetRelationData] = useState([]);
    const [getUserData, setGetUserData] = useState([]);
    const [getRelationFolder, setRelationFolder] = useState([]);
    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? result.Data : []);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TR_Relation', {}, setGetRelationData),
                fetchManyData('TR_User', {}, setGetUserData),
                fetchManyData('TR_RelationFolder', {}, setRelationFolder)
            ]);
        };

        fetchAllData();

        // 新增的判斷條件
        if (check === false) {
            setSelected([]);
            setChooseTargets([]);
        }
        if (btn !== 1) {
            setCheck(false);
        }
        //上一頁
        setTarget({previousPage:"/Relation"})
        
    },[btn, check]);


    //取消動作
    const cancel = () =>{
        setCheck(!check)
    }
    const addFolder =() =>{
        setBtn(1);
        setCheck(true);
    }

    //新增表格
    const [addTableDialogOpen, setAddTableDialogOpen] = React.useState(false);
    const [summit, setSummit] =  useState(false);
    const handleAddTableDialogOpen = () => {
        setAddTableDialogOpen(true);
    };

    //新增資料夾
    const [addDialogOpen, setAddDialogOpen] = React.useState(false);
    const handleAddDialogOpen = (index) => {
        setAddDialogOpen(true);
    };

    // 關閉對話框
    const handleClose = () => {
        setAddDialogOpen(false);
        setAddTableDialogOpen(false);
    };



    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(''); 

    const [data, setData] = useState(
        {
            id: 0,
            user_id: userId,
            identifier: 0,
            name: "",
            createTime: new Date(),
            updateTime: new Date(),
        }
    ) ;

    //------------------------------ 新增表格 ------------------------------//
    const handleAddTable = async (table_name) => {
        try {
            // 找到現有id最大值
            const maxId = await apiService.getMaxID("TR_Relation");
            //編碼
            const identifierWithUid = await apiService.encode("TR_Relation")
            const tmp = {
                id: maxId.Max_ID + 1,
                user_id: userId, //由SidebarContext登入者資訊取得
                userEdit_id: userId,
                relationFolder_id: 0,
                relationIdentifier: identifierWithUid.identifierWithUid, //需改成依照log資料數編碼,
                relationName: table_name,
                chooseList_checked: [],
                chooseList_indeterminate: [],
                chooseHC_IV : [],
                chooseSurvey_IV: [],
                relationCreateTime: new Date(),
                relationUpdateTime: new Date(),
            };
            // console.log("更新一筆 ", tmp);
            // 資料庫更動=>新增一筆
            const result = await apiService.insertOne("TR_Relation", tmp);
            if (!result.Status) {
                console.error('Failed to update document into + :', result.MSG);
                return;
            }

            const result2 = await apiService.getMany("TR_Relation", {}, null);
            if (!result2.Status) {
                console.error('Failed to fetch updated data: ', result2.MSG);
                return;
            }
            setGetRelationData(Array.isArray(result2.Data) ? result2.Data : []);

            setSuccessSnackbarOpen(true);
            setSnackbarMessage('成功新增一筆項目');
            handleClose();
    
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    //------------------------------ 新增資料夾 ------------------------------//
    const handleAddFolder = async (name) => {
        //編碼
        const identifierWithUid = await apiService.encode("TR_RelationFolder")
        const tmp = {
            ...data,
            id: getRelationFolder.length + 1,
            identifier: identifierWithUid.identifierWithUid,
            name: name
        };
        setData(tmp);

        const updatedItem = chooseTargets.map(item => ({
            ...item,
            userEdit_id: userId,
            relationFolder_id: getRelationFolder.length + 1,
            relationUpdateTime: new Date(),
        }));
        // 資料庫更動=>新增一筆
        try {
            const result = await apiService.insertOne("TR_RelationFolder", tmp)
            if (!result.Status) {
                console.error('Failed to update document into + :', result.MSG);
                return;
            }

            const result2 = await apiService.getMany("TR_RelationFolder", {}, null);
            if (!result2.Status) {
                console.error('Failed to fetch updated data: ', result2.MSG);
                return;
            }
            setRelationFolder(Array.isArray(result2.Data) ? result2.Data : []);

            //更新多個關係表
            for(let item of updatedItem){
                const updateResult = await apiService.updateOne("TR_Relation", {id: item.id}, item);
                if(!updateResult.Status){
                    console.error(`Failed to update relation with ${item.id}:`, updateResult.MSG);
                }
            }


            setSuccessSnackbarOpen(true);
            setSnackbarMessage('成功新增一筆項目');
            cancel();
            handleClose();

        } catch (error) {
            console.error('API request error:', error);
        }
    };


    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">關係表列表</h1>
                    <MuiGroupBtn btn={btn} setBtn={setBtn}/>
                    {btn === 1 ?
                        getRelationData.length !== 0 ?
                            <>
                                <RelationTable data={getRelationData} setData={setGetRelationData} folderData={getRelationFolder} userData={getUserData} 
                                    isEdit={true} 
                                    isCheck={check}
                                    target={target} setTarget={setTarget}
                                    setChooseTargets={setChooseTargets}
                                    selected={selected} setSelected={setSelected}

                                    userId={userId}
                                />
                                <div className="container_between">
                                    <div className="container_flex">
                                        <div className="common" onClick={()=>cancel()}>
                                            {!check ? "彙整成項目" 
                                            : <>
                                                <ClearIcon/>取消動作
                                            </>}
                                        </div>
                                        {chooseTargets.length !== 0 &&
                                            <div className="common final" onClick={()=>handleAddDialogOpen()}>建立資料夾</div>
                                        }
                                    </div>
                                    {/* <div className="common" onClick={()=>changePage("/AddRelation")}>建立新關係表</div> */}
                                    <div className="common" onClick={()=>handleAddTableDialogOpen()}>建立新關係表</div>
                                </div>
                            </>
                        :
                        <>
                            <TableContainer component={Paper} className="">
                                <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="subtitle">尚無關係表</div>
                                </Box>
                            </TableContainer>
                            <div className="container_between">
                                <div></div>
                                <div className="common" onClick={()=>changePage("/AddRelation")}>建立新關係表</div>
                            </div>
                        </>
                    :
                    <>
                        {getRelationFolder.length !== 0 ? 
                            <RelationFolderTable data={getRelationFolder} setData={setRelationFolder}
                            userData={getUserData} 
                            isEdit={true} 
                            setTarget={setTarget}
                            previousPage={"/Relation"} 
                            toPage={"/RelationFolder"}
                            />
                            :
                            <TableContainer component={Paper} className="">
                                <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="subtitle">尚無項目</div>
                                </Box>
                            </TableContainer>
                        }
                        <div className="container_between">
                            <div></div>
                            {/* 新增項目(新增資料夾) */}
                            <div className="common" onClick={()=> addFolder()}>新增項目</div>
                        </div>
                    </>
                    }
                </div>

                {/* 新增表格 */}
                <Dialog
                    open={addTableDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const name = formJson.name;

                        handleAddTable(name);
                    },
                    }}
                >
                    <DialogTitle>新增表格</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="name"
                            label="表格名稱"
                            fullWidth
                        />         
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
                {/* 新增資料夾 */}
                <Dialog
                    open={addDialogOpen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const name = formJson.name;

                        handleAddFolder(name);
                    },
                    }}
                >
                    <DialogTitle>新增資料夾</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="name"
                            label="項目名稱"
                            fullWidth
                        />         
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>
                {/* 新增成功 */}
                <Snackbar 
                    open={successSnackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                    }}
                    onClose={() => setSuccessSnackbarOpen(false)}
                >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </main>

        </>
    );
};
export default Relation;