

// const API_BASE_URL = 'http://localhost:5000/api';
const API_BASE_URL = 'https://rbs.meta-ai.com.tw/api';

// Helper function to handle the response
const handleResponse = async (response) => {
    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }
    return response.json();
};

export const updateRequestDataWithLookup = (requestData, fieldMappings) => {
    // 將要傳送的表單項目展開
    let updatedData = { ...requestData };

    // 將Lookup Table選擇的value轉換為實際的值存入
    fieldMappings.forEach(mapping => {
        // fieldName -> 指定的欄位名稱  // lookupTable -> TL的資料表
        const { fieldName, lookupTable } = mapping;
        // 從TL中找對應的名稱
        const selectedOption = lookupTable.find(item => item.id === requestData[fieldName]);
        // 替換值到原先欄位作為value
        if (selectedOption) {
            updatedData[fieldName] = selectedOption[fieldName];
        }
    });
    return updatedData;
};

const apiService = {
    // ======================== 接收 ========================
    getOne: async (collectionName, query, projection) => {
        const response = await fetch(`${API_BASE_URL}/get_one`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, query, projection }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    getMany: async (collectionName, query) => {
        const response = await fetch(`${API_BASE_URL}/get_many`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, query }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    getLookups: async (collectionName, query, projection) => {
        const response = await fetch(`${API_BASE_URL}/get_lookups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ collection_name: collectionName, query, projection }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 新增 ========================
    insertOne: async (collectionName, document) => {
        const response = await fetch(`${API_BASE_URL}/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, record: document }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    insertMany: async (collectionName, document) => {
        const response = await fetch(`${API_BASE_URL}/add_many`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, record: document }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 更新 ========================
    updateOne: async (collectionName, query, new_values) => {
        const response = await fetch(`${API_BASE_URL}/update_one`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, query: query, new_values: new_values }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 刪除 ========================
    deleteOne: async (collectionName, query) => {
        const response = await fetch(`${API_BASE_URL}/delete_one`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, query: query }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    deleteMany: async (collectionName, query) => {
        const response = await fetch(`${API_BASE_URL}/delete_many`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, query: query }),
            credentials: 'include',
        });
        return handleResponse(response);
    },
    // ======================== 驗證帳號 ========================
    checkAccount: async (query) => {
        const response = await fetch(`${API_BASE_URL}/checkAccount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ conditions: query }),
            credentials: 'include',
        });
        return handleResponse(response);
    },
    // ======================== 密碼驗證 ========================
    verifyPwd: async (conditions, password) => {
        const response = await fetch(`${API_BASE_URL}/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ conditions: conditions, password: password }),
            credentials: 'include',
        });
        return handleResponse(response);
    },
    // ======================== 寄送email ========================
    sendEmail: async ( accountNo ) => {
        const response = await fetch(`${API_BASE_URL}/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ accountNo }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 寄送email(重設密碼) ========================
    sendEmail2: async ( accountNo ) => {
        const response = await fetch(`${API_BASE_URL}/email2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ accountNo }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 登入 ========================
    register: async (collectionName, query, new_values) => {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, conditions: query, update_data: new_values }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 連結逾時 ========================
    verifyTimeStamp: async (timestamp) => {
        const response = await fetch(`${API_BASE_URL}/verify_timestamp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ timestamp }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 登出 ========================
    logout: async (conditions) => {
        const response = await fetch(`${API_BASE_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ conditions }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 重設密碼 ========================
    resetPwd: async (collectionName, query, new_values) => {
        const response = await fetch(`${API_BASE_URL}/reset_pwd`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName, conditions: query, update_data: new_values }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 讀取最大id ========================
    getMaxID: async (collectionName) => {
        const response = await fetch(`${API_BASE_URL}/get_max_id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName}),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 編碼 ========================
    encode: async (collectionName) => {
        const response = await fetch(`${API_BASE_URL}/encode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ table_name: collectionName}),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 查找 TR_RelationMatrix ========================
    getTRRelationMatrixByID: async (ids) => {
        const response = await fetch(`${API_BASE_URL}/tr_relation_matrix/lookup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ ids }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 保存 TR_RelationMatrix ========================
    saveTRRelationMatrix: async (chooseData) => {
        const response = await fetch(`${API_BASE_URL}/tr_relation_matrix/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(chooseData),
            credentials: 'include',
        });
        return handleResponse(response);
    },

    // ======================== 查找 健檢和問卷並格式化加入點選次數 ========================
    getTRItemByID: async (ids) => {
        const response = await fetch(`${API_BASE_URL}/tr_Item/lookup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ ids }),
            credentials: 'include',
        });
        return handleResponse(response);
    },

};

export default apiService;