import React, { useState } from 'react';

const TextWhite = (props) => {
    const { onChange, value, disabled, pwd, inquiry } = props;
    
    const [inputClassName, setInputClassName] = useState('input_white');
    const [promptText, setPromptText] = useState('');

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        onChange(inputValue);

        // if (inputValue === '') {
        //     setInputClassName('input_white empty_value');
        //     if(!inquiry) setPromptText(<div className="empty_value_message">請輸入內容</div>);
        // } else {
        //     // If text has been changed, reset the promptText
        //     setInputClassName('input_white');
        //     if(!inquiry) setPromptText('');
        // }
    };
    
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
        <div className='input_white_box'>
            <input
                className={inputClassName}
                type= {pwd ? (showPassword ? "text" : "password") : "text"}
                id={props.id}
                placeholder={props.placeholder}
                value={value}  // 使用狀態管理的值
                disabled= {disabled}
                onChange={handleInputChange}  // 設定值改變時的處理函數
                autoComplete ="off"
            />

            {/* 添加眼睛按鈕 */}
            {pwd && !disabled &&
                <button onClick={togglePasswordVisibility} className={showPassword ? "login_closeEye" : "login_eye"}></button>
            }
        </div>
        {promptText}
        </>
    );
};

export default TextWhite;

