import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks(props) {
  const { data, locationClass, category_id, target, setTarget } = props;

  const [rows, setRows] = useState([]);
  
  useEffect(()=> {
      if(data.length > 0 && category_id) {
          const extractedItems = data.map(item => {
            if(item.category_id === category_id){
              return item.name;
            } else {
              return null;
            }
          });
          setRows(extractedItems);
      }else if(data.length){
        const extractedItems = data.map(item => item.name);
        setRows(extractedItems);
      }
  }, [data]);

  // const location = useLocation();

  let [items, setItems] = React.useState([]);
  useEffect(() => {
    if (locationClass === "chooseHC" && target.chooseHC && rows.length > 0) {
        const initialItems = target.chooseHC.map(index => rows[index]);
        setItems(initialItems);
    }
    if (locationClass === "chooseSurvey" && target.chooseSurvey && rows.length > 0) {
      const initialItems = target.chooseSurvey.map(index => rows[index]);
      setItems(initialItems);
  }
  }, [locationClass, target, rows]);
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItems(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    const selectedIndexes = value.map(item => rows.indexOf(item));
    // console.log("Selected Indexexs: ", selectedIndexes);
    const newData = selectedIndexes;
    const dynamicKeys = {}
    if (locationClass){
      dynamicKeys[locationClass]= newData;
    }
    setTarget({ ...target, ...dynamicKeys })
  };


  return (
      <FormControl sx={{ width: 1 }}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={items}
            onChange={handleChange}
            // renderValue={(selected) => {
            //   const nonNullSelected = selected.filter(item => item !== null);
            //   if (nonNullSelected.length > 0) {
            //     return nonNullSelected.join(', ');
            //   } else {
            //     return '';
            //   }
            // }}
            renderValue={(selected) => {
              const nonNullSelected = selected.filter(item => item !== null);
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {nonNullSelected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
            }}
            MenuProps={MenuProps}


            sx={{
              // backgroundColor: protectEyesMode ? '#f0f0f0' : '#F6F7FB',
              // backgroundColor: '#F6F7FB',
              borderRadius: '5px',
              border: 0,
              outlineColor: 'red',
              '&.MuiOutlinedInput-root': {
                border: 'none' // 如果使用outlined樣式，也移除邊框
              },
              width: '100%',
              // minHeight:65
            }}
          >
            <MenuItem disabled value="">
              <em>由以下選擇，或略過問題</em>
            </MenuItem>
            {rows.map((name, index) => {
              if(name === null){
                return null;
              }else{
                return(
                  <MenuItem key={index} value={name}>
                    <Checkbox checked={items.indexOf(name) > -1}/>
                    <ListItemText primary={name} />
                  </MenuItem>
                )
              }
            })}
          </Select>
      </FormControl>
  );
}
