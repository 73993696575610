import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import ListControls from "../components/list/ListControls";

//api
import apiService from "../services/apiService";

const HealthCheckEdit = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    // 使用 useNavigate 鉤子進行路由導航
    const location = useLocation();

    const [getHCData, setGetHCData] = useState([]);

    useEffect(() => {
        // 排序資料
        const sortByCategorySort = (data) => {
            return data.sort((a, b) => a.sort - b.sort);
        };
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? sortByCategorySort(result.Data) : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TN_HealthCheckCategory', {}, setGetHCData)
            ]);
        };

        fetchAllData();
    },[]);

    const [hcdata, setHCData] = useState(
    {
        id: 0,
        identifier: 0,
        name: "",
        type: 0,
        createTime: new Date(),
        updateTime: new Date(),
        state: 0,
        sort: 0
    }
    ) ;

    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">健檢項目管理</h1>
                    <div className="row_small">
                        <h2 className="subtitle">編輯檢查類組(大項)</h2>
                        <h3 className="h3subtitle">新增、刪除、編輯，若要編輯類組底下項目(小項)，請點擊已有類組進入編輯。</h3>
                        <h3 className="h3subtitle"><span>現有 {getHCData.length} 個項目</span></h3>
                    </div>

                    <ListControls 
                        isEdit={true} 
                        data={getHCData} setData={setGetHCData} 
                        tableName={"TN_HealthCheckCategory"} addData={hcdata} setAddData={setHCData}

                        isCategory={"TN_HealthCheckItem"}  toPage={"/HealthCheckItemEdit"} location={location}
                    />

                </div>
            </main>
        </>
    );
};

export default HealthCheckEdit;