import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

//api
import apiService from "../../services/apiService";

function createData_one(userIdentifier, name, userAccount, userCreateTime, userUpdateTime, userPermissions, userState ) {
  return {
    userIdentifier: userIdentifier.toString(),
    name,
    userAccount,
    userCreateTime,
    userUpdateTime,
    userPermissions,
    userState
  };
}

function Row(props) {
  const { row, data, index, isEdit } = props;
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);


  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');



  //------------------------------ 更新 ------------------------------//
  const handleEditCheckup = (editName) => {
    const id = data.id;
    const originalItem = data;
    const userState = checked ? 1: 0;
    const updatedItem = {
      ...originalItem,
      userName: editName,
      userUpdateTime: new Date(),
      userState: userState,
      userPermissions: permissions
    };
    apiService.updateOne("TR_User", { id: id }, updatedItem)
    .then(() => {
      // 更新成功後重新加載頁面
      window.location.reload();
    })
    .catch(error => {
      console.error('API request error:', error);
    });

  };

  //------------------------------ 刪除 ------------------------------//
  const handleDeleteCheckup = () => {
    const id = data.id;
    apiService.deleteOne("TR_User", { id: id })
    .then(() => {
      // 更新成功後重新加載頁面
      window.location.reload();
    })
    .catch(error => {
      console.error('API request error:', error);
    });

  };

  const formatDateTime = (rawDateTime) => {
    const date = new Date(rawDateTime);
    const formattedDate = date.toLocaleDateString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const formattedTime = date.toLocaleTimeString('zh-CN', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

// 帳號狀態
const TL_AccountStatus =[
  { id: 0, label: "申請中", className: 'darkstatus blue', subclassName: 'status blue'},
  { id: 1, label: "已啟用", className: 'darkstatus green', subclassName: 'status green' },
];

const TL_PermissionLevel = [
  { id: 0, label: "待選取" },
  { id: 1, label: "最高管理權限" }, //後台人員(創建者)
  { id: 2, label: "設定權限" },
  { id: 3, label: "基礎權限" }, //醫師
];

const getAccountStatusLabel = (id) => {
  const status = TL_AccountStatus.find(status => status.id === id);
  return status ? status.label : '未知狀態';
};

const getPermissionLevelLabel = (id) => {
  const level = TL_PermissionLevel.find(level => level.id === id);
  return level ? level.label : '未知權限';
};

const [permissions, setPermissions] = React.useState(data.userPermissions);
const handleChangePermissions = (event) => {
  setPermissions(event.target.value);
};

const [checked, setChecked] = React.useState(data.userState === 1 ? true: false);
const handleChangeState = (event) => {
  setChecked(event.target.checked);
};

  return (
    <React.Fragment>
      
      <TableRow 
        hover
        role="checkbox"
        tabIndex={-1}
        
      >
        <TableCell scope="row">
          {index + 1}
        </TableCell>
        <TableCell scope="row">
          {row.userIdentifier}
        </TableCell>
        <TableCell scope="row">
          {row.name}
        </TableCell>
        <TableCell scope="row">
          {row.userAccount}
        </TableCell>
        <TableCell scope="row">
          {formatDateTime(row.userCreateTime)}
        </TableCell>
        <TableCell scope="row">
          {formatDateTime(row.userUpdateTime)}
        </TableCell>
        <TableCell scope="row">
          {getPermissionLevelLabel(row.userPermissions)}
        </TableCell>
        <TableCell scope="row">
          {getAccountStatusLabel(row.userState)}
        </TableCell>
        {isEdit &&
          <TableCell scope="row" align="center">
              {/* 更新 */}
              <ModeIcon className='edit_icon' onClick={(event) => {event.stopPropagation(); handleEditDialogOpen()}} />
              {/* 刪除 */}
              &ensp;
              <DeleteIcon className='edit_icon' onClick={(event) => {event.stopPropagation(); handleDeleteDialogOpen()}}/> 
          </TableCell>
        }
      </TableRow>

      {/* 更新 */}
      <Dialog
          open={editDialogOpen}
          onClose={handleClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);;
              const formJson = Object.fromEntries(formData.entries());
              const editName = formJson.editName;
              handleEditCheckup(editName);
              setSnackbarMessage('成功更新一筆項目');
              setSuccessSnackbarOpen(true);
              },
          }}
          sx={{width: 450, margin:'auto'}}
          >
              <DialogTitle>更新名稱</DialogTitle>
              <DialogContent>
                <DialogContentText>
                    編輯使用者名稱。
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="editName"
                    label="新名稱"
                    fullWidth
                    variant="outlined"
                    defaultValue={data.userName}
                />

                <FormControl sx={{ mt: 2 }} fullWidth>
                  <InputLabel htmlFor="max-width">權限等級</InputLabel>
                  <Select
                    autoFocus
                    value={permissions}
                    onChange={handleChangePermissions}
                    label="maxWidth"
                    inputProps={{
                      name: 'max-width',
                      id: 'max-width',
                    }}
                  >
                    <MenuItem value={0}>待選取</MenuItem>
                    <MenuItem value={1}>最高管理權限</MenuItem>
                    <MenuItem value={2}>設定權限</MenuItem>
                    <MenuItem value={3}>基礎權限</MenuItem>
                  </Select>
                </FormControl>

                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch 
                      checked={checked} 
                      onChange={handleChangeState} 
                    />
                  }
                  label={"帳號狀態: " + (checked ? "啟用" : "尚未啟用")}
                />


              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Update</Button>
              </DialogActions>

              {/* 更新成功 */}
              <Snackbar 
                    open={successSnackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                    }}
                    onClose={() => setSuccessSnackbarOpen(false)}
                    >
                    <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                    {snackbarMessage}
                    </MuiAlert>
              </Snackbar>
      </Dialog>


      {/* 刪除 */}
      <Dialog
          open={deleteDialogOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);;
              const formJson = Object.fromEntries(formData.entries());
              const deleteName = formJson.deleteName;
              // console.log("你刪除元素: "+ rows[index]);
              if(deleteName === row.name){
                handleDeleteCheckup();
                setSnackbarMessage('成功刪除一筆項目');
                setSuccessSnackbarOpen(true);
              }
              else {
                // 如果條件不成立，設置 Snackbar 的狀態並顯示提示訊息
                setSnackbarMessage('刪除失敗，輸入的名稱與選擇的名稱不符合');
                setErrorSnackbarOpen(true);
              }
            },
          }}
      >
        <DialogTitle>刪除帳戶</DialogTitle>
        <DialogContent>
          <DialogContentText>
            確定要刪除嗎?該帳戶永遠刪除。
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="deleteName"
            label="名稱"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" sx={{color: 'red'}}>Delete</Button>
        </DialogActions>
        {/* 刪除失敗 */}
        <Snackbar 
          open={errorSnackbarOpen} 
          autoHideDuration={6000} 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorSnackbarOpen(false)}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* 刪除成功 */}
        <Snackbar 
          open={successSnackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
          }}
          onClose={() => setSuccessSnackbarOpen(false)}
          >
          <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
          {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Dialog>

    </React.Fragment>


  );
}

Row.propTypes = {
  row: PropTypes.shape({
    userIdentifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userAccount: PropTypes.string.isRequired,
    userCreateTime: PropTypes.string.isRequired,
    userUpdateTime: PropTypes.string.isRequired,
    userPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    userState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};




export default function CollapsibleTable( props ) {
  const { data, isEdit } = props;
  // const rows = [
  //   // createData_one('關係表1', '2024-02-11 13:33', '2024-02-14 18:03', 'user 1'),
  //   // createData_one('關係表2', '2024-02-11 13:33', '2024-02-14 18:03', 'user 2'),
  // ];
  const rows = data.map((item) => createData_one(item.userIdentifier, item.userName, item.userAccount, item.userCreateTime, item.userUpdateTime, item.userPermissions, item.userState))
  


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="large">
        <TableHead>
          <TableRow>
            <TableCell align="left">No.</TableCell>
            <TableCell align="left">使用者唯一碼</TableCell>
            <TableCell align="left">使用者名稱</TableCell>
            <TableCell align="left">信箱</TableCell>
            <TableCell align="left">創建時間</TableCell>
            <TableCell align="left">更新時間</TableCell>
            <TableCell align="left">權限等級</TableCell>
            <TableCell align="left">帳戶狀態</TableCell>
            {isEdit &&
              <TableCell align="center">設定</TableCell>
            }

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return(
              <Row key={row.name} row={row}
                data={data[index]} index={index}
                isEdit={isEdit}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}