import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
//icon
import ArticleIcon from '@mui/icons-material/Article';
import FolderIcon from '@mui/icons-material/Folder';

export default function GroupSizesColors(props) {
    const {btn, setBtn} = props;
    
    const handleBtn = (options) =>{
        setBtn(options);
    }

    const buttons = [
        <Button key="one" variant={btn === 1 ? "contained" : "outlined"} onClick={()=>handleBtn(1)}><ArticleIcon/>　全部檔案</Button>,
        <Button key="two" variant={btn === 2 ? "contained" : "outlined"} onClick={()=>handleBtn(2)}><FolderIcon/>　資料夾</Button>,
    ];

    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            '& > *': {
            // m: 1,
            },
        }}
        >
            <ButtonGroup  color="primary" size="small" aria-label="Small button group">
                {buttons}
            </ButtonGroup>
        </Box>
    );
}