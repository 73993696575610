import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLabels(props) {
  const {data, category_id, setCategory_id, noStyle} = props;

  const handleChange = (event) => {
    setCategory_id(event.target.value);
    // console.log("選項id: ", event.target.value);
  };

  const [rows, setRows] = React.useState([]);

  useEffect(()=> {
    if(data.length > 0) {
      const extractedItems = data.map(item => ({
        id: item.id,
        name: item.name
      }));
      setRows(extractedItems);
    }
  }, [data]);

  return (
    <div className='sticky_lable'>
      <FormControl sx={noStyle ? {minWidth: 1} : { m: 0.7, minWidth: 120 }}>
        <Select
          value={category_id}
          onChange={handleChange}
          displayEmpty
          renderValue={(selectedId) => {
            if (selectedId === '') {
              return <em>選擇檢查類組</em>;
            }
            const selectedItem = rows.find(item => item.id === selectedId);
            return selectedItem ? selectedItem.name : '選擇檢查類組';
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{width:195}}
        >
          <MenuItem value="">
            <em>全部檢查類組</em>
          </MenuItem>

          {rows.map((item) =>
              <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
