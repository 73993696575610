import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext'

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import RelationTable from "../components/table/RelationTable";
import MuiGroupBtn from "../components/buttons/MuiGroupBtn";
import RelationFolderTable from "../components/table/RelationFolderTable";

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

//api
import apiService from "../services/apiService";

const HeatMap = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    const { resetParameters, target, setTarget, chooseTargets, setChooseTargets } = useFileContext();

    const [btn, setBtn] = useState(1);
    const [selected, setSelected] = useState([]); //選到的index
    // console.log("關係表選擇:",selected);


    useEffect(() => {
        // 當頁面加載時清除資料
        resetParameters();
    }, []);

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
        setTarget({ previousPage: "/HeatMap"})
    };

    const [getRelationData, setGetData] = useState([]);
    const [getUserData, setGetUserData] = useState([]);
    const [getRelationFolder, setGetRelationFolder] = useState([]);
    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        const fetchManyData = async (collection_name, query, setState) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                setState(Array.isArray(result.Data) ? result.Data : []);
                // console.log(`${collection_name} Data:`, result.Data);
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
            }
        };

        const fetchAllData = async () =>{
            await Promise.all([
                fetchManyData('TR_Relation', {}, setGetData),
                fetchManyData('TR_User', {}, setGetUserData),
                fetchManyData('TR_RelationFolder', {}, setGetRelationFolder)
            ]);
        };

        fetchAllData();

        // 新增的判斷條件
        if(btn !== 1){
            setSelected([]);
            setChooseTargets([]);
        }
    },[btn]);



    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <h1 className="maintitle">熱圖列表</h1>
                    <MuiGroupBtn btn={btn} setBtn={setBtn}/>
                    {btn === 1 ?
                        getRelationData.length !== 0 ?
                            <>
                                <RelationTable data={getRelationData} userData={getUserData} 
                                    isHeatMapCheck={true}
                                    target={target} setTarget={setTarget}
                                    setChooseTargets={setChooseTargets}
                                    selected={selected} setSelected={setSelected}
                                />
                                {chooseTargets.length !== 0 &&
                                    <div className="container_between">
                                        <div></div>
                                        <div className="common" onClick={()=> changePage("/HeatMapItem")}>生成熱圖</div>
                                    </div>
                                }
                            </>
                        :
                        <TableContainer component={Paper} className="">
                            <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="subtitle">尚無關係表</div>
                            </Box>
                        </TableContainer>
                    :
                    <>
                        {getRelationFolder.length !== 0 ? 
                            <RelationFolderTable data={getRelationFolder} userData={getUserData} 
                                isEdit={false} 
                                setTarget={setTarget}
                                previousPage={"/HeatMap"} 
                                toPage={"/HeatMapFolder"}
                            />
                            :
                            <TableContainer component={Paper} className="">
                                <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="subtitle">尚無項目</div>
                                </Box>
                            </TableContainer>
                        }
                    </>
                    }
                </div>
            </main>
        </>
    );
};
export default HeatMap;