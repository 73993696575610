import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';

import { Box, Checkbox, Collapse, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, ButtonBase } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

import Frame from "../Frame/Frame";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function createData(categoryName, itemName, totalRowCount) {
  return {
    categoryName, //條件化因子
    item: itemName,
    totalRowCount
  };
}

function Row(props) {
  const { setTarget,
          row, rowIndex,
          hcItemIDs, siIDs, colsLength, 
          chooseData, setChooseData, ///當下編輯的選擇
          disableRowData, disableColData,
          onCheckboxMouseDown, onCheckboxMouseUp, onCheckboxMouseOver, isSelecting
        } = props;

  const [open, setOpen] = React.useState(true);
  
  // console.log("hcItemIDs", hcItemIDs)
  // console.log("siIDs",siIDs)

  // ----------------- Table 菜單 -------------------//
  const [anchorEls, setAnchorEls] = useState({});
  const [anchorEls_item, setAnchorEls_item] = useState({});

  const handleContextMenu = (index) => (event) => {
    event.preventDefault(); // 防止默認的右键菜單
    setAnchorEls((prev) => (prev[index] ? { ...prev, [index]: null } : { ...prev, [index]: event.currentTarget }));
  };

  const handleContextMenu_item = (index) => (event) => {
    event.preventDefault(); // 防止默認的右键菜單
    setAnchorEls_item((prev) => (prev[index] ? { ...prev, [index]: null } : { ...prev, [index]: event.currentTarget }));
  };

  const handleClose = (index) => () => {
    setAnchorEls((prev) => ({ ...prev, [index]: null }));
  };

  const handleMultipleChange = (ids, rowIndex) =>{
    setTarget((prevTarget) => {
      const { chooseSurvey } = prevTarget;
      let newChooseSurvey = [...chooseSurvey];

      //確保 ids 是一個數組
      if(!Array.isArray(ids)){
        ids = [ids];
      }

      // 檢查是否所有 id 都存在於 chooseSurvey 中
      const allIdsExist = ids.every(id => newChooseSurvey.includes(id-1));

      if(allIdsExist){
        // 如果所有 id 都存在，則刪除它們
        ids.forEach((id) => {
          const index = newChooseSurvey.indexOf(id-1);
          if (index > -1) {
            newChooseSurvey.splice(index, 1);
          }
        });
      } else{
        // 如果不是所有 id 都存在，則添加缺少的 id
        ids.forEach((id) => {
          if (!newChooseSurvey.includes(id-1)) {
            newChooseSurvey.push(id-1);
          }
        });
      }


      return{
        ...prevTarget,
        chooseSurvey: newChooseSurvey,
      };
    });

    //關閉菜單
    handleClose(rowIndex)(); // 執行返回的函數
  }

  const handleChange = (index) => {
    setTarget((prevTarget) => {
      const { chooseSurvey } = prevTarget;
      const newChooseSurvey = chooseSurvey.includes(index)
        ? chooseSurvey.filter((item) => item !== index)
        : [...chooseSurvey, index];

      return {
        ...prevTarget,
        chooseSurvey: newChooseSurvey,
      };
    });

    //關閉菜單
    handleClose(index)(); // 執行返回的函數
  };

  // 整列中間狀態
  const rowIndeterminate = (xArray, yArrayOrY) => {
    // 處理 xArray，確保它是一个陣列
    const xs = Array.isArray(xArray) ? xArray : [xArray];
    
    let array;
    if (Array.isArray(yArrayOrY)) {
      // yAarryOry 是陣列，對每個y產生長度為length的陣列
      array = yArrayOrY.flatMap(y =>
        xs.map(x => [x, y])
        );
    } else {
      // yAarryOry 只是一個數值，代表針對一個問卷小項目去生成長度為length的陣列
      array = xs.map(x => [x, yArrayOrY]);
    }
    
    setChooseData(prevChooseData => {
      const newChooseData = [...prevChooseData];
      
      array.forEach(([x, y]) => {
        const index = newChooseData.findIndex(item => item[0] === x && item[1] === y);
        if (index !== -1) {
          newChooseData[index] = [x, y];
        } else {
          // 無選擇狀態 => 中間狀態
          newChooseData.push([x, y]);
        }
      });
      return newChooseData;
    });
  };
  
  // 整列有關係狀態
  const rowChecked = (xArray, yArrayOrY) => {
    // 處理 xArray，確保它是一个陣列
    const xs = Array.isArray(xArray) ? xArray : [xArray];
    
    let array;
    if (Array.isArray(yArrayOrY)) {
      // yAarryOry 是陣列，對每個y產生長度為length的陣列
      array = yArrayOrY.flatMap(y =>
        xs.map(x => [x, y, 1])
        );
    } else {
      // yAarryOry 只是一個數值，代表針對一個問卷小項目去生成長度為length的陣列
      array = xs.map(x => [x, yArrayOrY, 1]);
    }
    
    setChooseData(prevChooseData => {
      const newChooseData = [...prevChooseData];
      
      array.forEach(([x, y, status]) => {
        const index = newChooseData.findIndex(item => item[0] === x && item[1] === y);
        if (index !== -1) {
          newChooseData[index] = [x, y, status];
        } else {
          // 無選擇狀態 => 有關係狀態
          newChooseData.push([x, y, status]);
        }
      });
      
      return newChooseData;
    });
  };
  
  // 整列清除狀態
  const rowClear = (xArray, yArrayOrY) => {
    // 處理 xArray，確保它是一个陣列
    const xs = Array.isArray(xArray) ? xArray : [xArray];
  
    let array;
    if (Array.isArray(yArrayOrY)) {
      // yAarryOry 是陣列，對每個y產生長度為length的陣列
      array = yArrayOrY.flatMap(y =>
        xs.map(x => [x, y])
      );
    } else {
      // yAarryOry 只是一個數值，代表針對一個問卷小項目去生成長度為length的陣列
      array = xs.map(x => [x, yArrayOrY]);
    }
  
    setChooseData(prevChooseData => {
      let newChooseData = [...prevChooseData];
  
      array.forEach(([x, y]) => {
        newChooseData = newChooseData.filter(item => !(item[0] === x && item[1] === y));
      });
  
      return newChooseData;
    });
  };
  

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        {row.item.length === 1 ? (
          <TableCell></TableCell>
        ) : (
          // 下拉icon
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="large"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}

        {/* 大分類 */}
        <TableCell scope="row" onClick={handleContextMenu(rowIndex)}>
          <ButtonBase
                sx={{ width: '100%', height: '100%', p: 0.5, display: 'flex', justifyContent: 'start', alignItems: 'start', borderRadius: '8px',
                  '&:hover': {
                            backgroundColor: '#e0e0e0', // 设置ButtonBase hover时的背景色
                          },
                }}
          >
            {row.categoryName}
          </ButtonBase>
        </TableCell>

        <Menu
          id={`menu-${rowIndex}`}
          anchorEl={anchorEls[rowIndex]}
          keepMounted
          open={Boolean(anchorEls[rowIndex])}
          onClose={handleClose(rowIndex)}
        >
          {/* <MenuItem onClick={handleClose(row.id)}>{siIDs.join(", ")}</MenuItem> */}
          <MenuItem disabled>
            <span style={{ fontSize: '14px', color: '#7f7f7f' }}>設置不可編輯</span>
          </MenuItem>
          <MenuItem onClick={() => handleMultipleChange(siIDs, rowIndex)}>設為獨立變量 / <span style={{color: '#f04f4f'}}>取消</span></MenuItem>
          <MenuItem disabled>
            <span style={{ fontSize: '14px', color: '#7f7f7f' }}>全選選項</span>
          </MenuItem>
          <MenuItem onClick={()=> rowIndeterminate(hcItemIDs, siIDs)}>設為不知道(列) ⭢</MenuItem>
          <MenuItem onClick={()=> rowChecked(hcItemIDs, siIDs)}>設為有關係(列) ⭢</MenuItem>
          <MenuItem onClick={()=> rowClear(hcItemIDs, siIDs)}><span style={{color: '#f04f4f'}}>全部清除(列) ⭢</span></MenuItem>
      </Menu>

        <TableCell style={{ padding: 0 }} colSpan={colsLength + 1}>
          <Collapse in={open} unmountOnExit className='row'>
            {row.item.map((itemRow, rowItemIndex) => {
              const isConditionMetRow = disableRowData.includes(itemRow);
              return (
                <div key={rowItemIndex} style={{ display: 'table', width: '100%', borderCollapse: 'collapse' }} onClick={handleContextMenu_item(rowItemIndex)}>  
                  {/* 小分類 */}
                  <div style={{ display: 'table-cell', width: '13%', borderBottom: '1px solid rgb(224 224 224 / 45%)' }}>
                    <ButtonBase
                          sx={{ width: '100%', height: '100%', p: 0.5, display: 'flex', justifyContent: 'start', alignItems: 'start', borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: '#e0e0e0', // 设置ButtonBase hover时的背景色
                          }
                        }}
                    >
                      {itemRow}
                    </ButtonBase>

                    <Menu
                        id={`menu-${rowItemIndex}`}
                        anchorEl={anchorEls_item[rowItemIndex]}
                        keepMounted
                        open={Boolean(anchorEls_item[rowItemIndex])}
                        onClose={handleClose(rowItemIndex)}
                      >
                        {/* <MenuItem onClick={handleClose(rowItemIndex)}>{rowItemIndex}</MenuItem> */}
                        <MenuItem disabled>
                          <span style={{ fontSize: '14px', color: '#7f7f7f' }}>設置不可編輯</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleChange(siIDs[rowItemIndex]-1)}>設為獨立變量 / <span style={{color: '#f04f4f'}}>取消</span></MenuItem>
                        <MenuItem disabled>
                          <span style={{ fontSize: '14px', color: '#7f7f7f' }}>全選選項</span>
                        </MenuItem>
                        <MenuItem onClick={()=> rowIndeterminate(hcItemIDs, siIDs[rowItemIndex])}>設為不知道(列) ⭢</MenuItem>
                        <MenuItem onClick={()=> rowChecked(hcItemIDs, siIDs[rowItemIndex])}>設為有關係(列) ⭢</MenuItem>
                        <MenuItem onClick={()=> rowClear(hcItemIDs, siIDs[rowItemIndex])}><span style={{color: '#f04f4f'}}>全部清除(列) ⭢</span></MenuItem>

                    </Menu>
                  </div>

                  {isConditionMetRow ? (
                    Array.from({ length: colsLength }).map((_, index) => {
                      return (
                        <div key={index} style={{ display: 'table-cell', textAlign: 'center' }}>
                          <Checkbox {...label} size="large" disabled className="euid" disableRipple/>
                        </div>
                      );
                    })
                  ) : (
                    Array.from({ length: colsLength }).map((_, index) => {
                      const isConditionMetCol = disableColData.includes(index);
                      const chooseItem = chooseData.find(item => item[0] === hcItemIDs[index] && item[1] === siIDs[rowItemIndex]);
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'table-cell',
                            textAlign: 'center',
                            borderBottom: '1px solid rgb(224 224 224 / 45%)',
                            width: "100px",
                          }}

                          //傳入 x, y 位置
                          onMouseDown={(e) => {
                            if (!isConditionMetCol) {
                              onCheckboxMouseDown(e, index, rowItemIndex + row.totalRowCount);
                            }
                          }}
                          onMouseUp={(e) => {
                            if (!isConditionMetCol) {
                              onCheckboxMouseUp(e);
                            }
                          }}
                          onMouseOver={(e) => {
                            if (!isConditionMetCol) {
                              onCheckboxMouseOver(e, index, rowItemIndex + row.totalRowCount);
                            }
                          }}
                        >
                          {isConditionMetCol ? (
                            <Checkbox
                              {...label}
                              size="large"
                              disabled
                              className="euid"
                              disableRipple
                            />
                          ) : (
                            <Checkbox
                              {...label}
                              size="large"
                              color={chooseItem && chooseItem[2] === 1 ? "primary" : "default"}
                              checked={chooseItem ? chooseItem[2] === 1 : false}
                              indeterminate={chooseItem && chooseItem[2] === undefined}
                              disableRipple
                              sx={{
                                color: 'rgba(0, 0, 0, 0.54)', // 基本顏色
                                '&.Mui-checked': {
                                  color: '#1976d2', // 勾選顏色
                                },
                              }}

                                // onChange={() => editChooseList(hcItemIDs[index], siIDs[rowItemIndex])}

                                // onMouseDown={(e) => onCheckboxMouseDown(e, hcItemIDs[index], siIDs[rowItemIndex])}
                                // onMouseUp={onCheckboxMouseUp}
                                // onMouseOver={(e) => onCheckboxMouseOver(e, hcItemIDs[index], siIDs[rowIt         
                                // 傳入 x, y 位置
                                // onMouseDown={(e) => onCheckboxMouseDown(e, index, rowItemIndex+row.totalRowCount  )} 
                                // onMouseUp={onCheckboxMouseUp}
                                // onMouseOver={(e) => onCheckboxMouseOver(e, index, rowItemIndex+row.totalRowCount )}
                            />
                          )}
                        </div>
                      );
                      
                    })
                  )}
                </div>
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    item: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    categoryName: PropTypes.string.isRequired
  }).isRequired,
};

export default function CollapsibleTable(props) {
  const { data,  target, setTarget,//從前一頁獲取data
  
    // -----已儲存在TR_Relation的原始資料-----//
    // relationData,
    checkedData, indeterminateData,

    firstColData, secondColData,
    chooseData, setChooseData, //當下編輯的選擇

    //筆刷狀態
    selectedBrushIndex
    
  } = props;

  // console.log("元件內",chooseData);

  const [cols, setCols] = useState([]);
  const [hcItemIDs, setHCItemIDs] = useState([]);

  const [thFirstCols, setThFirstCols] = useState([]);
  const [chooseHCItems, setChooseHCItems] = useState([]);
  const HCIndexes = [];
  const [thSecondCols, setThSecondCols] = useState([]);
  const [siIDs, setSiIDs] = useState([]);
  const [chooseSurveyItems, setChooseSurveyItems] = useState([]);

  const [loading, setLoading] = useState(0); // State for loading indicator
  const [itemCounts, setItemCounts] = useState([]); 

  const [isSelecting, setIsSelecting] = useState(false);
  const [startSelection, setStartSelection] = useState(null);
  const [endSelection, setEndSelection] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);
  // console.log("儲存:",selectedItems)

  useEffect(() => {
    //健檢項目
    //data: name、id
    if (data.length > 0) {
      const extractedItems = data.map(item => item.name);
      setCols(extractedItems);
      const extractedIDs = data.map(item => item.id);
      setHCItemIDs(extractedIDs);
    }
    //健檢項目=>獨立變量
    if (data.length > 0 && target.chooseHC) {
      const extractedItems = target.chooseHC.map(index => {
        const matcheData = data.find(item => item.id === index + 1); //item_uid 從 1 計算 
        return matcheData ? matcheData.name : null;
      });
      setChooseHCItems(extractedItems.filter(item => item !== null));
    }
    //條件化因子
    if (firstColData.length > 0) {
      const extractedItems = firstColData.map(item => item.name);
      setThFirstCols(extractedItems);
    }
    //問卷項目
    if (secondColData.length > 0) {
      const extractedItems = secondColData.map(item => item.name);
      setThSecondCols(extractedItems);
      const extractedIDs = secondColData.map(item => item.id);
      setSiIDs(extractedIDs);
    }
    // 切割數量
    if (secondColData.length > 0) {
      const extractedItems = secondColData.map(item => item.category_id);
      const itemCountsObject = extractedItems.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});
      
      // 按順序存儲每個元素的出現次數
      const itemCountsArray2 = extractedItems.map((item, index, self) => {
        // 對於數組中的每個元素，只在它第一次出現時記錄其計數
        if (self.indexOf(item) === index) {
            return itemCountsObject[item];
        }
        return null; // 非首次出現的元素用null填充
      }).filter(item => item !== null); // 過濾掉null值

      setItemCounts(itemCountsArray2);
    }


    //問卷項目=>獨立變量
    if (secondColData.length > 0 && target.chooseSurvey) {
      const extractedItems = target.chooseSurvey.map(index => {
        const matcheData = secondColData.find(item => item.id === index + 1); //item_uid 從 1 計算
        return matcheData ? matcheData.name : null;
      });
      setChooseSurveyItems(extractedItems.filter(item => item !== null));
    }

    if (checkedData || indeterminateData) {
      //-------TR_Relation關係表chooseList_checked id 轉[x,y,1]、chooseList_indeterminate id 轉[x,y]---------//
      const extractedCheckedItems = checkedData ? checkedData.map(item => [item.hcItem_id, item.surveyItem_id, 1]) : [];
      const extractedIndeterminateItems = indeterminateData ? indeterminateData.map(item => [item.hcItem_id, item.surveyItem_id]) : [];

      // 過濾重複資料
      const newItems = [...extractedCheckedItems, ...extractedIndeterminateItems].filter(newItem => 
        !chooseData.some(existingItem => 
          JSON.stringify(existingItem) === JSON.stringify(newItem)
        )
      );

      setChooseData([...chooseData, ...newItems]);

    }

  }, [data, firstColData, secondColData, target]);

  let startIndex = 0;
  const slicedSecondCols = itemCounts.map(count => {
    const slicedItems = thSecondCols.slice(startIndex, startIndex + count);
    const slicedIDs = siIDs.slice(startIndex, startIndex + count); //另外儲存一個切割的問卷項目id
    startIndex += count;
    return { slicedItems, slicedIDs };
  });

  let totalRowCount = 0 //目前累計行數
  const rows = thFirstCols.map((category, index) => {
    if (slicedSecondCols[index]) { //資料載入與否判定
      const row = createData(category, slicedSecondCols[index].slicedItems, totalRowCount);
      totalRowCount = totalRowCount + slicedSecondCols[index].slicedItems.length;
      return row;
    } else {
      return null;
    }
  });

  useEffect(() => {
    // 在資料加載完成後設置 loading 為 1
    if (data.length > 0 && firstColData.length > 0 && secondColData.length > 0) {
      setLoading(1);
    } else {
      // 在5秒後檢查是否有資料載入，若沒有則將 loading 設置為 2
      const timeoutId = setTimeout(() => {
        if (loading === 0) {
          setLoading(2);
        }
      }, 5000);
      // 清除setTimeout以避免記憶體洩漏
      return () => clearTimeout(timeoutId);
    }
  }, [data, firstColData, secondColData]);
  
  const editChooseList = (x, y) => {
    setChooseData(prevChooseData => {
      const index = prevChooseData.findIndex(item => item[0] === x && item[1] === y);
  
      switch (selectedBrushIndex) {
        case 1:
          // Indeterminate筆刷
          if (index !== -1) {
            // 已存在 => 改為中間狀態
            return prevChooseData.map((item, idx) => idx === index ? [x, y] : item);
          } else {
            // 無選擇狀態 => 中間狀態
            return [...prevChooseData, [x, y]];
          }
  
        case 2:
          // Checked筆刷
          if (index !== -1) {
            // 已存在 => 改為有關係狀態
            return prevChooseData.map((item, idx) => idx === index ? [x, y, 1] : item);
          } else {
            // 不存在 => 新增有關係狀態
            return [...prevChooseData, [x, y, 1]];
          }
  
        case 3:
          // 橡皮擦
          if (index !== -1) {
            // 已存在 => 移除
            return prevChooseData.filter((item, idx) => idx !== index);
          }
          return prevChooseData;
  
        default:
          // 翻轉筆刷 case 0
          if (index !== -1) {
            if (prevChooseData[index][2] === 1) {
              // 已選狀態 => 移除狀態
              return prevChooseData.filter((item, idx) => idx !== index);
            } else {
              // 中間狀態 => 已選狀態
              return prevChooseData.map((item, idx) => idx === index ? [x, y, 1] : item);
            }
          } else {
            // 無選擇狀態 => 中間狀態
            return [...prevChooseData, [x, y]];
          }
      }
    });
  };
  
  const onCheckboxMouseDown = (e, x, y) => {
    setIsSelecting(true);
    setStartSelection({ x, y });
    setEndSelection({ x, y }); // 如果是點擊，開頭和結果同一個
  }
  
  const onCheckboxMouseUp = () => {
    setIsSelecting(false);
    setStartSelection(null);
    // setEndSelection(null);
    setSelectedItems(getSelectedItems());
  }
  
  const onCheckboxMouseOver = (e, x, y) => {
    if (isSelecting) {
      // console.log('結果', x,y)
      setEndSelection({ x, y });
    }
  }

  const getSelectedItems = () => {
    if (!startSelection || !endSelection) {
      return [];
    }
    
    const startX = Math.min(startSelection.x, endSelection.x);
    const endX = Math.max(startSelection.x, endSelection.x);
    const startY = Math.min(startSelection.y, endSelection.y);
    const endY = Math.max(startSelection.y, endSelection.y);
  
    const selectedItems = [];
  
    for (let i = startX; i <= endX; i++) {
      for (let j = startY; j <= endY; j++) {
        selectedItems.push({ hcItemID: hcItemIDs[i], siID: siIDs[j] });
      }
    }
  
    return selectedItems;
  }

  const applyChangesToSelectedItems = () => {
    // console.log("檢查",selectedItems)
    selectedItems.forEach(item => {
      editChooseList(item.hcItemID, item.siID);
    });
  }

  useEffect(() => {
    if (!isSelecting) {
      applyChangesToSelectedItems();
    }
  }, [isSelecting]);

  // ----------------- Table 菜單 -------------------//
  const [anchorEls, setAnchorEls] = useState({});

  const handleContextMenu = (index) => (event) => {
    event.preventDefault(); // 防止默認的右键菜單
    setAnchorEls((prev) => (prev[index] ? { ...prev, [index]: null } : { ...prev, [index]: event.currentTarget }));

    handleClose(index-1)
  };

  const handleClose = (index) => () => {
    setAnchorEls((prev) => ({ ...prev, [index]: null }));
  };

  const handleChange = (index) => {
    setTarget((prevTarget) => {
      const { chooseHC } = prevTarget;
      const newChooseHC = chooseHC.includes(index)
        ? chooseHC.filter((item) => item !== index)
        : [...chooseHC, index];

      return {
        ...prevTarget,
        chooseHC: newChooseHC,
      };
    });

    //關閉菜單
    handleClose(index)(); // 執行返回的函數
  };

  // 整行中間狀態
  const colIndeterminate = (x, length) => {
    const array = Array.from({ length }, (_, i) => [x, i + 1]);

    setChooseData(prevChooseData => {
      const newChooseData = [...prevChooseData];

      array.forEach(([x, y]) => {
        const index = prevChooseData.findIndex(item => item[0] === x && item[1] === y);
            // Indeterminate筆刷
            if (index !== -1) {
              // 已存在 => 改為中間狀態
              newChooseData[index] = [x, y]
            } else {
              // 無選擇狀態 => 中間狀態
              newChooseData.push([x, y]);
            }
        });

        return newChooseData;
      });
  };

  // 整行有關係狀態
  const colChecked = (x, length) =>{
    const array = Array.from({ length }, (_, i) => [x, i + 1, 1]);

    setChooseData(prevChooseData => {
      const newChooseData = [...prevChooseData];

      array.forEach(([x, y]) => {
        const index = prevChooseData.findIndex(item => item[0] === x && item[1] === y);
            // Checked筆刷
            if (index !== -1) {
              // 已存在 => 改為有關係狀態
              newChooseData[index] = [x, y, 1]
            } else {
              // 無選擇狀態 => 有關係狀態
              newChooseData.push([x, y, 1]);
            }
        });

        return newChooseData;
      });
  }

  // 整行清除狀態
  const colClear = (x, length) => {
    const array = Array.from({ length }, (_, i) => [x, i + 1]);
  
    setChooseData(prevChooseData => {
      let newChooseData = [...prevChooseData];
  
      array.forEach(([x, y]) => {
        const index = newChooseData.findIndex(item => item[0] === x && item[1] === y);
        if (index !== -1) {
          // 如果已存在 => 移除
          newChooseData = newChooseData.filter((_, idx) => idx !== index);
        }
      });
  
      return newChooseData;
    });
  };
  

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
      {loading === 1 ?
        // <Frame onSelectionChange={handleSelectionChange}>
        <Frame>
          <Table aria-label="sticky collapsible table" size="small">
            <TableHead sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white'
            }}>
              <TableRow>
                <TableCell align="left" sx={{ width: "0%" }}></TableCell>
                <TableCell align="left" sx={{ width: "11%" }}>條件化因子</TableCell>
                <TableCell align="left" sx={{ width: "11%", padding: 0 }}>問卷項目</TableCell>
                {cols.map((colName, index) => {
                  const isConditionMet = chooseHCItems.includes(colName);
                  if (isConditionMet) {
                    HCIndexes.push(index);
                  }
                  return (
                    <TableCell
                      key={index}
                      align="center"
                      sx={{ width: "100px" }}
                      // sx={{ width: "7%" }}
                      onClick={handleContextMenu(index)}
                    >
                      <ButtonBase
                        sx={{ width: '100%', height: '100%', p: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: '#e0e0e0', // 設置ButtonBase hover時的背景色
                          },

                        }}
                      >
                        {colName}
                      </ButtonBase>

                      <Menu
                        id={`menu-${index}`}
                        anchorEl={anchorEls[index]}
                        keepMounted
                        open={Boolean(anchorEls[index])}
                        onClose={handleClose(index)}
                      >
                        {/* <MenuItem onClick={handleClose(index)}>{hcItemIDs[index]}</MenuItem> */}
                        <MenuItem disabled>
                          <span style={{ fontSize: '14px', color: '#7f7f7f' }}>設置不可編輯</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleChange(hcItemIDs[index] -1)}>設為獨立變量 / <span style={{color: '#f04f4f'}}>取消</span></MenuItem>
                        <MenuItem disabled>
                          <span style={{ fontSize: '14px', color: '#7f7f7f' }}>全選選項</span>
                        </MenuItem>
                        <MenuItem onClick={()=> colIndeterminate( hcItemIDs[index], siIDs.length)}>設為不知道(行) ⭣</MenuItem>
                        <MenuItem onClick={()=> colChecked( hcItemIDs[index], siIDs.length)}>設為有關係(行) ⭣</MenuItem>
                        <MenuItem onClick={()=> colClear( hcItemIDs[index], siIDs.length)}><span style={{color: '#f04f4f'}}>全部清除(行) ⭣</span></MenuItem>
                      </Menu>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <Row 
                  key={index} 

                  target={target} setTarget = {setTarget}
                  row={row} rowIndex={index}
                  hcItemIDs={hcItemIDs} 
                  siIDs={slicedSecondCols[index].slicedIDs} 
                  colsLength={cols.length} 
                  chooseData={chooseData}
                  setChooseData={setChooseData}
                  disableRowData={chooseSurveyItems} 
                  disableColData={HCIndexes} 
                  onCheckboxMouseDown={onCheckboxMouseDown}
                  onCheckboxMouseUp={onCheckboxMouseUp}
                  onCheckboxMouseOver={onCheckboxMouseOver}
                  isSelecting={isSelecting}
                />
              ))}
            </TableBody>
          </Table>
        </Frame>
        : loading === 0 ?
          <Box style={{ width: '100%' }}>
            <LinearProgress />
          </Box>
          :
          <Box style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="subtitle">尚無資料</div>
          </Box>
      }
      
    </TableContainer>
  );
}
