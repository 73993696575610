import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';

import Sidebar from "../layouts/Sidebar";
import TableContainerTri from "../components/table/TableContainerTri";
import Topbar from "../layouts/Topbar";
import SplitBtn from "../components/buttons/SplitBtn";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//api
import apiService from "../services/apiService";

const Survey = () => {
    const [mainWidth, setMainWidth] = useState("250px");
    const { target } = useFileContext();
    //從前一頁獲取data
    const location = useLocation();
    // console.log(location);
    
    // 追蹤當下的勾選狀態
    const { chooseList, setChooseList } = useFileContext();

    //筆刷選擇
    const [selectedBrushIndex, setSelectedBrushIndex] = React.useState(0);

    const handleSave = () => {
        setChooseList(chooseList); //更新暫存的選擇//不可直接編輯暫存(資料狀態不可控)
        changePage("/HealthCheck");
    }

    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        // console.log("最後要加入state的更新:", updatedChooseList)
    
        if (option === "/AddRelation" || option === "/HealthCheck" || option === "/Survey") {
            // 更新路由的狀態，將新的 ChooseList 賦值給 data.ChooseList
            navigate(option, { 
                state: { 
                    ...location.state,
                    data: {
                        ...location.state.data,
                    } 
                } 
            });
        } else{
            // 如果跳轉到其他頁面，清除暫存資料
            navigate(option, { state: null });
        }
    };
    
    

    const category_id = location.state?.target?.id ?? null;
    const name = location.state?.target?.name ?? null;

    const [getHCData, setGetHCData] = useState([]);
    const [getHCItemData, setGetHCItemData] = useState([]);
    const [getSurveyData, setGetSurveyData] = useState([]);
    const [getSurveyItemData, setGetSurveyItemData] = useState([]);
    // 排序資料
    const sortBySort = (data) => {
        return data.sort((a, b) => a.sort - b.sort);
    };
    // 排序資料(小項目)
    const sortByCategorySort = (data, categoryMap) => {
        return data.sort((a, b) => {
            // 先按照 categoryMap 中的排序順序排序
            if (categoryMap[a.category_id] !== categoryMap[b.category_id]) {
                return categoryMap[a.category_id] - categoryMap[b.category_id];
            }
            // 如果 category_id 相同，再按照 sort 排序
            return a.sort - b.sort;
        });
    };

    const createCategoryMap = (data) => {
        const categoryMap = {};
        data.forEach((item, index) => {
            categoryMap[item.id] = index;
        });
        return categoryMap;
    };

    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        // 獲取數據的通用函數
        const fetchManyData = async (collection_name, query, setState, sortFunc) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                if (collection_name === 'TN_HealthCheckCategory' || collection_name === 'TN_SurveyCategory') {
                    const sortedData = Array.isArray(result.Data) ? sortBySort(result.Data) : [];
                    setState(sortedData);
                    return sortedData;
                } else {
                    const sortedData = Array.isArray(result.Data) ? sortFunc(result.Data) : [];
                    setState(sortedData);
                    return sortedData;
                }
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
                return [];
            }
        };

        const fetchAllData = async () => {
            const hcData = await fetchManyData('TN_HealthCheckCategory', {}, setGetHCData, data => data);
            const hcCategoryMap = createCategoryMap(hcData);
            await fetchManyData('TN_HealthCheckItem', {category_id: category_id}, setGetHCItemData, data => sortByCategorySort(data, hcCategoryMap));

            const surveyData = await fetchManyData('TN_SurveyCategory', {}, setGetSurveyData, data => data);
            const surveyCategoryMap = createCategoryMap(surveyData);
            await fetchManyData('TN_SurveyItem', {}, setGetSurveyItemData, data => sortByCategorySort(data, surveyCategoryMap));
        };

        fetchAllData();
    },[category_id]);


    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="row_small">
                        <h1 className="maintitle">問卷和健檢表建立工具</h1>
                        <h2 className="subtitle container"> 建立新關係表 <KeyboardDoubleArrowRightIcon /> 選擇健檢項目 <KeyboardDoubleArrowRightIcon /><span>選擇問卷項目</span></h2>
                    </div>

                    <h2 className="subtitle"><span>{name}</span></h2>

                    <div>
                        <div className="container_between container_flex_margin-top_bottom">
                            <div></div>
                            <SplitBtn selectedBrushIndex={selectedBrushIndex} setSelectedBrushIndex={setSelectedBrushIndex} simple={true}/>
                        </div>
                        <TableContainerTri 
                            data={getHCItemData} firstColData={getSurveyData} secondColData={getSurveyItemData} 
                            target={target}
                            chooseData={chooseList} setChooseData={setChooseList}
                            selectedBrushIndex={selectedBrushIndex}
                        />

                        <div className="container_flex_small container_flex_end sticky_lable_button">
                            <IndeterminateCheckBoxIcon color="action"/>不知道<CheckBoxIcon color="primary"/>有關係<CheckBoxOutlineBlankIcon color="action"/>沒關係<CheckBoxOutlineBlankIcon color="disabled" className="ff"/>獨立變量(禁用)
                        </div>
                    </div>

                    <div className="container_between">
                        <div className="common" onClick={()=>handleSave()}>Previous and Update</div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Survey;

