import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../context/FileContext';
import { useSidebar } from '../context/SidebarContext';

import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar";
import TableContainerTri from "../components/table/TableContainerTri";
import Dropdowns from "../components/dropdowns/Dropdowns";
import Select from "../components/dropdowns/Select";
import SplitBtn from "../components/buttons/SplitBtn";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// import LinearProgressWithLabel from "../components/wait/LinearProgressWithLabel"

import CombinedTable from "../components/table/CombinedTable";

//api
import apiService from "../services/apiService";
import { VolumeUp } from "@mui/icons-material";

const RelationItemEdit = () => {
    const [mainWidth, setMainWidth] = useState("250px");

    const { userId } = useSidebar();

    const { target, setTarget, chooseList, setChooseList } = useFileContext();
    //chooseList, setChooseList = 追蹤當下的編輯選擇


    // 使用 useNavigate 鉤子進行路由導航
    const navigate = useNavigate();
    const changePage = (option) => {
        navigate(option);
    };

    const [open, setOpen] = React.useState(false); //開關dialog
    const [alarmOpen, setalarmOpen] = React.useState(false); 
    const [alarmOpen2, setalarmOpen2] = React.useState(false); 

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setalarmOpen(false);
        setalarmOpen2(false);
    };

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    
    //表格篩選用
    const [categoryHC_id, setCategoryHC_id] = useState('');
    const [getHCItemData_filtered, setGetHCItemData_filtered] = useState([]);

    const [getHCData, setGetHCData] = useState([]);
    const [getHCItemData, setGetHCItemData] = useState([]);
    
    const [categorySC_id, setCategorySC_id] = useState();
    const [getSurveyData, setGetSurveyData] = useState([]);
    const [getSurveyItemData, setGetSurveyItemData] = useState([]);

    //筆刷選擇
    const [selectedBrushIndex, setSelectedBrushIndex] = React.useState(0);

    useEffect(() => {
        if(selectedBrushIndex === 4){
            setalarmOpen2(true)
        } else if(selectedBrushIndex === 5){
            window.location.reload();
        }
    }, [selectedBrushIndex]);

    const handleClear = () => {
        setChooseList([]);
        // setChooseIDListChecked([]);
        // setChooseIDListIndeterminate([]);
    };

    //重整時清空
    useEffect(() => {
        handleClear();
    }, []);

    // 排序資料
    const sortBySort = (data) => {
        return data.sort((a, b) => a.sort - b.sort);
    };
    // 排序資料(小項目)
    const sortByCategorySort = (data, categoryMap) => {
        return data.sort((a, b) => {
            // 先按照 categoryMap 中的排序順序排序
            if (categoryMap[a.category_id] !== categoryMap[b.category_id]) {
                return categoryMap[a.category_id] - categoryMap[b.category_id];
            }
            // 如果 category_id 相同，再按照 sort 排序
            return a.sort - b.sort;
        });
    };

    const createCategoryMap = (data) => {
        const categoryMap = {};
        data.forEach((item, index) => {
            categoryMap[item.id] = index;
        });
        return categoryMap;
    };

    useEffect(() => {
        // get_many  -----------------------------------------------------------------
        // 獲取數據的通用函數
        const fetchManyData = async (collection_name, query, setState, sortFunc) => {
            try {
                const result = await apiService.getMany(collection_name, query, null);
                if (collection_name === 'TN_HealthCheckCategory' || collection_name === 'TN_SurveyCategory') {
                    const sortedData = Array.isArray(result.Data) ? sortBySort(result.Data) : [];
                    setState(sortedData);
                    return sortedData;
                }  else {
                    const sortedData = Array.isArray(result.Data) ? sortFunc(result.Data) : [];
                    setState(sortedData);
                    return sortedData;
                }
            } catch (error) {
                console.error(`${collection_name} API request error:`, error);
                return [];
            }
        };

        const fetchAllData = async () => {
            const hcData = await fetchManyData('TN_HealthCheckCategory', {}, setGetHCData, data => data);
            const hcCategoryMap = createCategoryMap(hcData);
            // 如果categoryHC_id沒資料，找到 hcCategoryMap 中值為 0 的鍵
            const categoryHCId = categoryHC_id ? categoryHC_id : parseInt(Object.keys(hcCategoryMap).find(key => hcCategoryMap[key] === 0), 10);
            await fetchManyData('TN_HealthCheckItem', {category_id: categoryHCId}, setGetHCItemData_filtered, data => sortByCategorySort(data, hcCategoryMap));
            await fetchManyData('TN_HealthCheckItem', {}, setGetHCItemData, data => sortByCategorySort(data, hcCategoryMap));

            const surveyData = await fetchManyData('TN_SurveyCategory', {}, setGetSurveyData, data => data);
            const surveyCategoryMap = createCategoryMap(surveyData);
            await fetchManyData('TN_SurveyItem', {}, setGetSurveyItemData, data => sortByCategorySort(data, surveyCategoryMap));
        };

        fetchAllData();

        // setChooseList(chooseData)

    }, [categoryHC_id, categorySC_id]);


    const [getCheckedData, setGetCheckedData] = useState([]);
    const [getIndeterminateData, setGetIndeterminateData] = useState([]);
    //--------已選狀態和中間狀態區分--------//
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await apiService.getTRRelationMatrixByID({
                    checked_ids: target.targetItem.chooseList_checked,
                    indeterminate_ids: target.targetItem.chooseList_indeterminate
                });
                
                if (result.Status) {
                    const { checked_data, indeterminate_data } = result;

                    // 已選狀態 => 多加 checked 標籤
                    const updatedCheckedData = checked_data.map(item => ({
                        ...item,
                        checked: 1
                    }));

                    setGetCheckedData(updatedCheckedData);
                    setGetIndeterminateData(indeterminate_data);
                }
            } catch (error) {
                console.error("Error fetching relation data:", error);
            }
        };
        
        fetchData();

    }, []);

    
    const combinedData = [...getCheckedData, ...getIndeterminateData];

    const oldRelationData = Object.values(combinedData).map(item => {
        if(item.checked){
            return [item.hcItem_id, item.surveyItem_id, item.checked]
        }else{
            return [item.hcItem_id, item.surveyItem_id]
        }
    });

    // 比較兩個數組是否相等的函數
    function arraysEqual(arr1, arr2) {
        // 如果數組長度不同，則它們不相等
        if (arr1.length !== arr2.length) return false;
    
        // 排序
        const compareArrays = (a, b) => {
            if (a.length !== b.length) return a.length - b.length;
            for (let i = 0; i < a.length; i++) {
                if (a[i] !== b[i]) return a[i] - b[i];
            }
            return 0;
        };
    
        // 對數組進行排序，以確保順序一致性
        const sortedArr1 = arr1.slice().sort(compareArrays);
        const sortedArr2 = arr2.slice().sort(compareArrays);
    
        // 對每個元素進行比較
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i].length !== sortedArr2[i].length) return false;
            for (let j = 0; j < sortedArr1[i].length; j++) {
                if (sortedArr1[i][j] !== sortedArr2[i][j]) return false;
            }
        }
    
        // 所有元素都相等
        return true;
    }

    //------------------------------ 更新 ------------------------------//
    const [chooseIDListChecked, setChooseIDListChecked] = useState([]); //最後儲存的id//非常相關
    const [chooseIDListIndeterminate, setChooseIDListIndeterminate] = useState([]); //最後儲存的id//中間相關

    const [loading, setLoading] =  useState(false);
    const [progress, setProgress] = React.useState(0); //進度
    const handleSave = async () => {
        try {
            setLoading(true)
            const response = await apiService.saveTRRelationMatrix(chooseList);

            if (response.Status) {
                setChooseIDListChecked(response.checked_ids);
                setChooseIDListIndeterminate(response.indeterminate_ids);

            } else {
                console.error('Server error:', response.MSG);
            }
    
            // 在API請求完成後設置進度為100%
            setProgress(100);

            setLoading(false);
            handleClose();

            setSnackbarMessage('成功更新關係表，加載中...');
            setSuccessSnackbarOpen(true);

        } catch (error) {
            console.error('API request error:', error);
            setLoading(false);
        }
    };


    //progress 100% 時，呼叫 更新api
    useEffect(() => {
        const finishAddData  = async()=>{
            const id = target.targetItem.id;
            const originalItem = target.targetItem;
            const tmp = {
                ...originalItem,
                userEdit_id: userId,//最後編輯者
                chooseHC_IV: target.chooseHC,
                chooseSurvey_IV: target.chooseSurvey,
                chooseList_checked: chooseIDListChecked,
                chooseList_indeterminate: chooseIDListIndeterminate,
            };
            // 資料庫更動=>更新一筆
            const result = await apiService.updateOne("TR_Relation", { id: id }, tmp);
            if (!result.Status) {
                console.error('Failed to update document into + :', result.MSG);
                return;
            }
            
            // 更新完成後，重新從 API 獲取最新的資料
            const result2 = await apiService.getMany("TR_Relation", { id: id }, null);
            if (!result2.Status) {
                console.error('Failed to update document:', result2.MSG);
                return;
            }

            //--------已選狀態和中間狀態區分--------//
            const result3 = await apiService.getTRRelationMatrixByID({
                checked_ids: result2.Data[0].chooseList_checked,
                indeterminate_ids: result2.Data[0].chooseList_indeterminate
            });
            
            if (result3.Status) {
                const { checked_data, indeterminate_data } = result3;

                // 已選狀態 => 多加 checked 標籤
                const updatedCheckedData = checked_data.map(item => ({
                    ...item,
                    checked: 1
                }));

                setGetCheckedData(updatedCheckedData);
                setGetIndeterminateData(indeterminate_data);
            }

            setProgress(0);
        };

        if (progress === 100) {
            finishAddData();
        }
    }, [progress]);
    
    return (
        <>
            <Topbar/>
            <Sidebar setMainWidth={setMainWidth}/>
            <main className="main" style={{ left: mainWidth }}>
                <div className="row">
                    <div className="container_gap">
                        <h1 className="maintitle">關係表編輯</h1>
                        {
                            (
                                !arraysEqual(chooseList, oldRelationData) ||
                                !arraysEqual(target.targetItem.chooseHC_IV, target.chooseHC) ||
                                !arraysEqual(target.targetItem.chooseSurvey_IV, target.chooseSurvey)
                            ) && (
                                <h3 className="h3subtitle"><span className="alarm">尚有更新資料，請點擊儲存按鈕...</span></h3>
                            )
                        }
                    </div>
                    
                        <h2 className="subtitle"><span>{target.targetItem.relationName}</span></h2>
                    
                    <div>
                    <div className="container_between">
                        <Select data={getHCData} category_id={categoryHC_id} setCategory_id={setCategoryHC_id}/>
                        <SplitBtn selectedBrushIndex={selectedBrushIndex} setSelectedBrushIndex={setSelectedBrushIndex}/>
                    </div>
                        <div className="box">
                            <TableContainerTri 
                                data={getHCItemData_filtered} firstColData={getSurveyData} secondColData={getSurveyItemData} 
                                target={target} setTarget={setTarget}
                                isEdit={true} checkedData={getCheckedData} indeterminateData={getIndeterminateData}
                                chooseData={chooseList} setChooseData={setChooseList}
                                selectedBrushIndex={selectedBrushIndex}
                            />
                        </div>
                        <div className="container_flex_small container_flex_end sticky_lable_button">
                            <IndeterminateCheckBoxIcon color="action"/>不知道<CheckBoxIcon color="primary"/>有關係<CheckBoxOutlineBlankIcon color="action"/>沒關係<CheckBoxOutlineBlankIcon color="disabled" className="ff"/>獨立變量(禁用)
                        </div>
                    </div>

                    <div className="container_between">
                        <div className="common" onClick={!arraysEqual(chooseList, oldRelationData) ? () => setalarmOpen(true) : () => changePage(target.previousPage)}>Previous</div>

                        {/* <div className={`common final ${isBlinking ? 'blinking' : ''}`} onClick={handleClickOpen}>Update and Save</div> */}
                        {
                            (
                            !arraysEqual(chooseList, oldRelationData) ||
                            !arraysEqual(target.targetItem.chooseHC_IV, target.chooseHC) ||
                            !arraysEqual(target.targetItem.chooseSurvey_IV, target.chooseSurvey)
                            ) && (
                                <div className={`common final`} onClick={handleClickOpen}>Update and Save</div>
                            )
                        }
                    </div>

                    {/* 檢查使用 */}
                    {/* <p>{!arraysEqual(chooseList, oldRelationData) ? "不一樣" : "一樣"}</p>
                    <p>{!arraysEqual(target.targetItem.chooseHC_IV, target.chooseHC) ? "不一樣" : "一樣"}</p>
                    <p>{!arraysEqual(target.targetItem.chooseSurvey_IV, target.chooseSurvey) ? "不一樣" : "一樣"}</p> */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {loading ? "資料加入請稍等..." : "確認更新關係表?"}
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">
                            先前資料將被覆蓋，往後可在編輯功能中進行編輯。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                            <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={()=>{
                                handleSave(); 
                            }}
                            autoFocus 
                            sx={{color: 'red'}}
                            >
                                Agree
                            </Button>
                    </DialogActions>
                </Dialog>

                {/* 提醒 */}
                <Dialog
                    open={alarmOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"尚有更新項目，確定離開?"}
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">
                            點擊[Update and Save]按鈕儲存待編輯項目 或 放棄這次更新。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={()=>{
                            handleClose(); 
                            changePage(target.previousPage);
                        }}
                        autoFocus 
                        sx={{color: 'red'}}
                        >
                            直接離開
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* 清空提醒 */}
                <Dialog
                    open={alarmOpen2}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"確定要清空?"}
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">
                            若要回復先前狀態，請重整頁面或點擊[回復]按鈕。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={()=>{
                            handleClose();
                            setSelectedBrushIndex(0); //回到預設筆刷
                        }}>取消</Button>
                        <Button onClick={()=>{
                            handleClose(); 
                            handleClear();
                            setSelectedBrushIndex(0); //回到預設筆刷
                        }}
                        autoFocus 
                        sx={{color: 'red'}}
                        >
                            清空
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>

            <Snackbar 
                open={successSnackbarOpen} 
                autoHideDuration={6000} 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                onClose={() => setSuccessSnackbarOpen(false)}
            >
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default RelationItemEdit;